import { useMemo, useState } from 'react';
import { BaseLayout } from 'shared/layouts';
import {
  Button,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { ToolbarList } from 'shared/components';
import { Environment } from 'shared/environment';
import { IMeta, IQuestion } from 'shared/interfaces';
import { QuestionService } from 'shared/services/api/Questions';
import { AlertQuestionAdd } from '../Components/AlertQuestionAdd';
import { UseQueryResult, useQuery } from 'react-query';

interface IData {
  data: IQuestion[];
  meta: IMeta;
}

export const QuestionList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<string>();
  const [id, setId] = useState<number>();
  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  const { data, isLoading }: UseQueryResult<IData, Error> = useQuery(
    ['questions', page],
    async () => await QuestionService.getAll(page),
  );

  function handleClickOpenNew() {
    setType('new');
    setOpen(true);
  }

  async function handleClickOpenEdit(id: number) {
    setType('edit');
    await setId(id);
    setOpen(true);
  }

  return (
    <BaseLayout
      title="Home"
      toolBar={
        <ToolbarList
          title="Lista de Questionários"
          showInputSearch={false}
          showButtonNew={true}
          textButtonNew="Novo"
          onClickNew={() => handleClickOpenNew()}
        />
      }
    >
      <AlertQuestionAdd
        setId={setId}
        getData={() => {
          console;
        }}
        open={open}
        setOpen={setOpen}
        type={type}
        id={id}
      />

      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Ações</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Questões</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data.map((row: IQuestion) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Button variant="contained" color="primary" size="small" onClick={() => handleClickOpenEdit(row.id)}>
                    Editar
                  </Button>
                </TableCell>
                <TableCell>{row.question}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          {data?.meta.total === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={6}>
                  <LinearProgress variant="indeterminate" color="success" />
                </TableCell>
              </TableRow>
            )}
            {data?.meta.total > 0 && data?.meta.total > Environment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={page}
                    count={Math.ceil(data?.meta.total / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ page: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </BaseLayout>
  );
};
