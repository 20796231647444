import { Box, Typography } from '@mui/material';
import React from 'react';

// import { Container } from './styles';

const Error404: React.FC = () => {
  return (
    <Box width={'100vw'} height="100vh" display={'flex'} alignItems={'center'}>
      <Typography variant="h1" color={'red'} textAlign="center">
        ERROR 404 Pagina não encontrada
      </Typography>
      ;
    </Box>
  );
};

export default Error404;
