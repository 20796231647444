import React, { ReactNode } from 'react';
import { useTheme, Box, Drawer, List, useMediaQuery, Skeleton, Typography } from '@mui/material';
import { useDrawerContext } from 'shared/contexts/';
import ListItemLink from 'shared/components/ListItemLink';
import MenuBar from '../MenuBar';

const SiderBar = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const { drawerOptions, isDrawerOpen, toggleDrawerOpen } = useDrawerContext();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mleft = smDown ? 0 : theme.spacing(28);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <MenuBar />
        <Drawer
          open={isDrawerOpen}
          variant={smDown ? 'temporary' : 'permanent'}
          onClose={toggleDrawerOpen}
          anchor="left"
        >
          <Box sx={{ height: theme.spacing(8), width: theme.spacing(28) }} />
          {drawerOptions && (
            <List component="nav" sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              {drawerOptions?.map((drawerOption) => (
                <ListItemLink
                  to={drawerOption.path}
                  key={drawerOption.id}
                  icon={drawerOption.icon}
                  label={drawerOption.label}
                  onClick={smDown ? toggleDrawerOpen : undefined}
                />
              ))}
            </List>
          )}
          {!drawerOptions && (
            <>
              <Skeleton
                variant="rectangular"
                sx={{
                  marginX: 1,
                  width: theme.spacing(28),
                  height: theme.spacing(6),
                  mb: 1,
                }}
              />
              <Skeleton
                variant="rectangular"
                sx={{
                  marginX: 1,
                  width: theme.spacing(28),
                  height: theme.spacing(6),
                  mb: 1,
                }}
              />
              <Skeleton
                variant="rectangular"
                sx={{
                  marginX: 1,
                  width: theme.spacing(28),
                  height: theme.spacing(6),
                  mb: 1,
                }}
              />
            </>
          )}
        </Drawer>
      </Box>
      <Box height="90vh" ml={mleft} padding={theme.spacing(2)} overflow="hidden">
        {children}
      </Box>
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © União Norte Brasileira - '}
        {new Date().getFullYear()} {' | Departamento de Bussiness Inteligence'}
      </Typography>{' '}
    </>
  );
};

export default SiderBar;
