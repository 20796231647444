import { IUser } from 'shared/interfaces/auth.types';
import { APIBase } from '../apiBase';

export const LOCAL_STORAGE_KEY__ACCESS_TOKEN = '@EA_SOCIOMETRIA_ACCESS_TOKEN';

export const auth = async (email: string, password: string) => {
  try {
    const request = await APIBase.post('auth', {
      email,
      password,
    });

    return request.data;
  } catch (error) {
    return error;
  }
};

export const recoverPassword = async (email: string) => {
  try {
    const { data } = await APIBase.post('auth/recover', { email });
    return data;
  } catch (error) {
    const data = { message: error };
    return data;
  }
};

export const resetPassword = async (email: string, password: string, token: string) => {
  try {
    const { data } = await APIBase.post('auth/reset', {
      email,
      password,
      token,
    });
    return data;
  } catch (error) {
    const data = { message: error };
    return data;
  }
};

export function storeBeareToken(token: string) {
  APIBase.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export function setUserLocalStorage(user: IUser | null) {
  localStorage.setItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN, JSON.stringify(user));
}

export function getUserLocalStorage() {
  const json = localStorage.getItem(LOCAL_STORAGE_KEY__ACCESS_TOKEN);
  if (!LOCAL_STORAGE_KEY__ACCESS_TOKEN) {
    return null;
  }

  const user = JSON.parse(json);

  return user ?? null;
}
