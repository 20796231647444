import { ReactNode } from 'react';
import { useTheme } from '@mui/material';
import { Box } from '@mui/system';

interface ILayoutBaseProps {
  title: string;
  toolBar?: ReactNode;
  children: any;
}
export const BaseLayout: React.FC<ILayoutBaseProps> = ({ children, toolBar: toolBar }) => {
  const theme = useTheme();

  return (
    <Box height="100%" display="flex" flexDirection="column" gap={1}>
      {toolBar && <Box>{toolBar}</Box>}

      <Box flex={1} overflow="auto" padding={1} bgcolor={theme.palette.background.paper}>
        {children}
      </Box>
    </Box>
  );
};
