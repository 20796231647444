import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { IVFormErrors, VForm, useVForm } from 'shared/forms';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { QuestionQuizzesService } from 'shared/services/api';
import { AutoCompletQuestion } from './AutoCompletQuestion';
import { IQuestionQuizz } from 'shared/interfaces';
import { Environment } from 'shared/environment';
interface alertProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  //getData(): void;
  idQuestion: number;
}

export const AlertQuestionQuizz: React.FC<alertProps> = ({ open, setOpen, idQuestion }) => {
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState<IQuestionQuizz[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  interface IFormData {
    question_id: number;
    quiz_id: number;
  }

  const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
    question_id: yup.number().notRequired(),
    quiz_id: yup.number().notRequired(),
  });

  const handleClose = () => {
    //   getData();
    setOpen(false);
  };

  async function getQuestionsQuizz(id: number) {
    setIsLoading(true);
    try {
      const result = await QuestionQuizzesService.getByQuizz(id);
      if (result instanceof Error) {
        alert(result.message);
      } else {
        setTotalCount(result.length);
        setRows(result);
      }
    } catch (error) {
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getQuestionsQuizz(idQuestion);
  }, []);

  const handleSave = async (data: IFormData) => {
    try {
      await formValidationSchema.validate(data, { abortEarly: false });

      setIsLoading(true);
      const validatedData = {
        question_id: data.question_id,
        quiz_id: idQuestion,
      };
      const result = await QuestionQuizzesService.create(validatedData);

      if (!(result instanceof Error)) {
        getQuestionsQuizz(idQuestion);
        alert('Gravado com sucesso');
      }
    } catch (errors) {
      const validationErrors: IVFormErrors = {};

      errors.inner.forEach((error: any) => {
        if (!error.path) return;

        validationErrors[error.path] = error.message;
      });

      formRef.current?.setErrors(validationErrors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <VForm ref={formRef} onSubmit={handleSave}>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Adição do Questionário</DialogTitle>
        <DialogContent>
          <AutoCompletQuestion isExternalLoading={isLoading} />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Fechar
          </Button>
          <LoadingButton
            loading={isLoading}
            color="secondary"
            loadingIndicator="Salvando…"
            variant="outlined"
            onClick={save}
          >
            Salvar
          </LoadingButton>
        </DialogActions>
        <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', bgcolor: 'GrayText', fontSize: 18 }}>Questões</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.question}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            {totalCount === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}
            <TableFooter>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <LinearProgress variant="indeterminate" color="success" />
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        </TableContainer>
      </Dialog>
    </VForm>
  );
};
