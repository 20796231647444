import { Environment } from 'shared/environment';
import { APIBase } from '../apiBase';
import { IUser, IMeta } from 'shared/interfaces';
import { getUserLocalStorage } from '../authentication';

interface IData {
  data: IUser[];
  meta: IMeta;
  statusCode: number;
  type: string;
}

type TUSerWithTotalCount = {
  data: IData;
  totalCount: number;
};
const userOffline: IUser = getUserLocalStorage();

const getAll = async (page = 1, union: number, entity: number, filter = '%'): Promise<TUSerWithTotalCount | Error> => {
  try {
    let urlRelativa: string;
    if (userOffline.role === 'union') {
      urlRelativa = `user/list/union/${union}?filter=${filter}&page=${page}&limit=${Environment.LIMITE_DE_LINHAS}`;
    } else {
      urlRelativa = `user/list/entity/${entity}?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}&filter=${filter}`;
    }

    const { data, headers } = await APIBase.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: number): Promise<IData | Error> => {
  try {
    const { data } = await APIBase.get(`/user/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IUser): Promise<IUser | Error> => {
  try {
    const { data } = await APIBase.post<IUser>('/user', dados);

    if (data) {
      return data;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (id: number, dados: IUser): Promise<IUser | Error> => {
  try {
    const { data } = await APIBase.put(`/user/${id}`, dados);

    if (data) {
      return data;
    }
    return new Error('Erro ao Atualizar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const disable = async (id: number, status: boolean): Promise<IUser | Error> => {
  try {
    const { data } = await APIBase.put(`/user/status/${id}`, {
      status: status,
    });
    if (data) {
      return data;
    }
    return new Error('Erro ao desabilitar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao apagar o registro.');
  }
};

export const UserService = {
  getAll,
  create,
  getById,
  updateById,
  disable,
};
