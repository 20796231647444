import {
  Button,
  Checkbox,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { BaseLayout } from 'shared/layouts';

import { ToolbarList } from 'shared/components';
import { AlertSchoolQuestion } from '../Components/AlertSchoolQuestion';

import { useEntityContext, useSchoolContext } from 'shared/contexts';
import { Environment } from 'shared/environment';
import { useAuth as useAuthContext } from 'shared/hooks/';

export const SchoolsList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id = 'all' } = useParams();
  const { profile } = useAuthContext();
  const navigate = useNavigate();
  const { dataEntity, getEntities } = useEntityContext();
  const [checked, setChecked] = useState([]);
  const [open, setOpen] = useState(false);
  const { dataSchool, getSchools, totalCount, loading } = useSchoolContext();

  const entityCode = id === 'all' ? profile.entity : id;
  const initial = dataEntity?.filter((item) => item.code === Number(entityCode))[0].initials;
  console.log(dataEntity);

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  useEffect(() => {
    if (id === 'all' && profile.role !== 'school') {
      getEntities(1, profile.union, profile.entity);
    }
    getEntities(1, profile.union, profile.entity);
    getSchools(profile.union, initial, Number(profile.school_code));
  }, [page, id, profile]);

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  function opeQuestions() {
    if (checked.length === 0) {
      alert('Selecione um escola');
      return;
    }
    setOpen(true);
  }

  function clearChecked() {
    checked.length = 0;
  }

  return (
    <BaseLayout
      title="Home"
      toolBar={
        <ToolbarList
          title={`Lista de Escolas - ${id !== 'all' ? initial : ''} - Total: ${dataSchool?.length}`}
          showInputSearch={false}
          showButtonNew={false}
          textButtonNew="Sincronizar"
          buttonIcon="sync"
          showButtonCustom={true}
          textButtonCustom="Adicionar Questionário"
          onClickCustom={() => opeQuestions()}
          showButtonBack
        />
      }
    >
      {open && <AlertSchoolQuestion idsSchool={checked} open={open} setOpen={setOpen} getData={clearChecked} />}
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Selecione</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Ações na séries</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Sigla</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Nome</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSchool
              ?.filter((s) => s.code && s.initials)
              ?.map((row) => (
                <TableRow key={row.code}>
                  <TableCell width={'5%'}>
                    <Checkbox
                      color="secondary"
                      key={row.code}
                      checked={checked.indexOf(row.school_id) !== -1}
                      tabIndex={-1}
                      onClick={handleToggle(row.school_id)}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </TableCell>
                  <TableCell width={'16%'}>
                    <Button
                      sx={{ mr: 0.2 }}
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => navigate(`/Private/Grades/${row.entity_code}/${row.code}`)}
                    >
                      Acessar
                    </Button>
                  </TableCell>
                  <TableCell>{row.initials}</TableCell>
                  <TableCell>{row.name}</TableCell>
                </TableRow>
              ))}
          </TableBody>
          {totalCount === 0 && !loading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}

          <TableFooter>
            {loading && (
              <TableRow>
                <TableCell colSpan={7}>
                  <LinearProgress variant="indeterminate" color="success" />
                </TableCell>
              </TableRow>
            )}
            {totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={page}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ page: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </BaseLayout>
  );
};
