import React from 'react';

import { Box, Skeleton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import ButtonOutlined from './ButtonOutlined';

interface IToolbarDetailsProps {
  title: string;
  textButtomNew?: string;
  textButtomBack?: string;
  iconButtomBack?: string;

  showButtonNew?: boolean;
  showButtonBack?: boolean;
  showButtonDelete?: boolean;
  showButtonSave?: boolean;
  showButtonSaveAndClose?: boolean;

  showNewButtonLoading?: boolean;
  showBackButtonLoading?: boolean;
  showButtonDeleteLoading?: boolean;
  showSaveButtonLoading?: boolean;
  showButtonSaveAndCloseLoading?: boolean;

  onClickNew?: () => void;
  onClickBack?: () => void;
  onClickDelete?: () => void;
  onClickSave?: () => void;
  onClickSaveAndClose?: () => void;
}
export const ToolbarDetails: React.FC<IToolbarDetailsProps> = ({
  title,
  textButtomNew = 'Novo',
  textButtomBack = 'Voltar',
  iconButtomBack = 'arrow_back',

  showButtonNew: showButtonNew = true,
  showButtonBack: showButtonBack = true,
  showButtonDelete: showButtonDelete = true,
  showButtonSave: showButtonSave = true,
  showButtonSaveAndClose: showButtonSaveAndClose = false,
  showNewButtonLoading: showNewButtonLoading = false,
  showBackButtonLoading: showBackButtonLoading = false,
  showButtonDeleteLoading: showButtonDeleteLoading = false,
  showSaveButtonLoading: showSaveButtonLoading = false,
  showButtonSaveAndCloseLoading: showButtonSaveAndCloseLoading = false,

  onClickNew: onClickNew,
  onClickBack: onClickBack,
  onClickDelete,
  onClickSave: onClickSave,
  onClickSaveAndClose: onClickSaveAndClose,
}) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" paddingX={2} justifyContent="space-between">
      <Typography
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipses"
        variant={smDown ? 'h6' : mdDown ? 'h5' : 'h5'}
      >
        {title}
      </Typography>
      <Box gap={1} padding={1} display="flex" height={theme.spacing(7)}>
        {showButtonSave && !showSaveButtonLoading && (
          <ButtonOutlined icon="save" label="Salvar" onClick={onClickSave} type="contained" />
        )}

        {showSaveButtonLoading && <Skeleton width={110} height={60} />}

        {showButtonSaveAndClose && !showButtonSaveAndCloseLoading && !smDown && !mdDown && (
          <ButtonOutlined icon="save" label="Salvar e voltar" onClick={onClickSaveAndClose} type="outlined" />
        )}

        {showButtonSaveAndCloseLoading && !smDown && !mdDown && <Skeleton width={180} height={60} />}

        {showButtonDelete && !showButtonDeleteLoading && (
          <ButtonOutlined icon="delete" label="Apagar" onClick={onClickDelete} type="outlined" />
        )}

        {showButtonDeleteLoading && <Skeleton width={110} height={60} />}

        {showButtonNew && !showNewButtonLoading && !smDown && (
          <ButtonOutlined icon="add" label={textButtomNew} onClick={onClickNew} type="outlined" />
        )}

        {showNewButtonLoading && !smDown && <Skeleton width={110} height={60} />}

        {/* {showButtonBack && (showButtonNew || showButtonDelete || showButtonSave || showButtonSaveAndClose) && (
          <Divider variant="middle" orientation="vertical" />
        )} */}

        {showButtonBack && !showBackButtonLoading && (
          <ButtonOutlined icon={iconButtomBack} label={textButtomBack} onClick={onClickBack} type="outlined" />
        )}

        {showBackButtonLoading && <Skeleton width={110} height={60} />}
      </Box>
    </Box>
  );
};
