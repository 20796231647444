import { useEffect, useMemo, useState } from 'react';
import { BaseLayout } from 'shared/layouts';
import {
  Button,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ToolbarList } from 'shared/components';
import { Environment } from 'shared/environment';
import { IGrade } from 'shared/interfaces';
import { useAuth as useAuthContext } from 'shared/hooks';
import { useEntityContext, useSchoolContext } from 'shared/contexts';
import { GradeService } from 'shared/services/api/Grades';

export const GradesList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { school, entity } = useParams();
  const { profile } = useAuthContext();
  const { dataSchool } = useSchoolContext();
  const { dataEntity } = useEntityContext();
  const navigate = useNavigate();
  const [rows, setRows] = useState<IGrade[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const selectedSchool = dataSchool.find((item) => item.code === Number(school));
  const schoolName = selectedSchool ? selectedSchool.name : 'Escola não encontrada';
  const entityInitials = dataEntity?.find((item) => item.code === Number(entity))?.initials;
  const page = useMemo(() => Number(searchParams.get('page') || '1'), [searchParams]);

  const getGrades = async (page: number, union: number) => {
    setIsLoading(true);
    try {
      const result = await GradeService.getBySchool(page, union, entityInitials, Number(school));
      if (result instanceof Error) {
        throw result;
      }
      setTotalCount(result.totalCount);
      setRows(result.data);
    } catch (error) {
      if (error.message === 'Nenhum registro encontrado.') {
        return;
      }
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getGrades(page, profile.union);
  }, [page, entity, school]);

  return (
    <BaseLayout
      title="Home"
      toolBar={
        <ToolbarList
          title={`${schoolName} | Total de séries: ${rows.length}`}
          showInputSearch={false}
          showButtonNew={false}
          textButtonNew="Sincronizar"
          onClickNew={() => console.log}
          showButtonBack
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Ações na turma</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Codigo da Série</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Nome da Série</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell width={'16%'}>
                  <Button
                    sx={{ mr: 0.2 }}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => navigate(`/Private/Classes/${row.entity_code}/${row.school_code}/${row.code}`)}
                  >
                    Acessar
                  </Button>
                </TableCell>
                <TableCell width={'15%'}>{row.code}</TableCell>
                <TableCell>{row.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          {totalCount === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={6}>
                  <LinearProgress variant="indeterminate" color="success" />
                </TableCell>
              </TableRow>
            )}
            {totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={page}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ page: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </BaseLayout>
  );
};
