import { ReactNode } from 'react';
import { Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';

interface ILayoutBaseProps {
  title: string;
  toolBar?: ReactNode;
  children: any;
}
export const DialogBaseLayout: React.FC<ILayoutBaseProps> = ({ children, toolBar: toolBar, title }) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const theme = useTheme();

  return (
    <Box height="100%" display="flex" flexDirection="column" gap={1}>
      <Typography
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipses"
        variant={smDown ? 'h6' : mdDown ? 'h5' : 'h5'}
        textAlign={'center'}
        p={1}
        bgcolor={theme.palette.primary.main}
        color={theme.palette.primary.contrastText}
      >
        {title}
      </Typography>

      <Box flex={1} overflow="auto" padding={1} bgcolor={theme.palette.background.paper}>
        {children}
      </Box>
      {toolBar && <Box mb={1}>{toolBar}</Box>}
    </Box>
  );
};
