import { useEffect, useMemo, useState } from 'react';
import { BaseLayout } from 'shared/layouts';
import {
  Button,
  Checkbox,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { ListAlt } from '@mui/icons-material';

import { ToolbarList } from 'shared/components';
import { useDebounce, useAuth as useAuthContext } from 'shared/hooks/';
import { Environment } from 'shared/environment';
import { IStudent } from 'shared/interfaces';
import { useEntityContext, useSchoolContext } from 'shared/contexts';
import { StudentService } from 'shared/services/api/Stundets';
import AlertSelectQuestion from './Components/AlertSelectQuestion';

export const StudentList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { school, entity, grade, classe } = useParams();
  const { debounce } = useDebounce();
  const { profile } = useAuthContext();
  const { dataSchool } = useSchoolContext();
  const { dataEntity } = useEntityContext();
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<IStudent[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [idQuestion, setIdQuestion] = useState<number>();
  const initial = dataEntity.filter((item) => item.code === Number(entity))[0].initials;

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);
  const [local, setLocal] = useState('');

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  function getStudents(page: number, union: number) {
    setIsLoading(true);
    debounce(() => {
      StudentService.getByClasses(union, initial, Number(school), Number(grade), classe, search).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          if (result.message === 'Nenhum registro encontrado.') {
            return;
          }
          alert(result.message);
        } else {
          setTotalCount(result.data.length);
          setRows(result.data);
        }
      });
    });
  }
  function openSelecQuestion(id: number, local: string) {
    setIdQuestion(id);
    setLocal(local);
    setOpen(true);
  }

  useEffect(() => {
    getStudents(page, profile.union);
  }, [page, entity, school, search]);

  return (
    <BaseLayout
      title="Home"
      toolBar={
        <ToolbarList
          title={`Lista de Alunos | ${
            dataSchool.filter((item) => item.code === Number(school))[0].name
          } | Turma - ${classe} | Total de alunos: ${totalCount}`}
          showInputSearch={true}
          textOfSearch={search}
          onChangeSearchText={(text) => setSearchParams({ search: text, page: '1' }, { replace: true })}
          showButtonNew={true}
          textButtonNew="Gerar QRCode"
          buttonIcon="qr_code"
          onClickNew={() => openSelecQuestion(0, 'qrcode')}
          showButtonBack
        />
      }
    >
      {open && (
        <AlertSelectQuestion
          entity={Number(entity)}
          grade={Number(grade)}
          classe={classe}
          local={local}
          isLoading={isLoading}
          open={open}
          setOpen={setOpen}
          school={dataSchool.filter((item) => item.code === Number(school))[0]}
          student={idQuestion}
          initial={dataEntity.filter((item) => item.code === Number(entity))[0].initials}
        />
      )}
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Ações</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Codigo</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Nome</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Sexo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.code}>
                <TableCell width="35%">
                  <Checkbox {...label} />
                  <Button
                    variant="text"
                    color="secondary"
                    startIcon={<ListAlt />}
                    size="small"
                    onClick={() => openSelecQuestion(row.code, 'null')}
                  >
                    Responder Questionário
                  </Button>
                </TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.sexo}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          {totalCount === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={6}>
                  <LinearProgress variant="indeterminate" color="success" />
                </TableCell>
              </TableRow>
            )}
            {/* {totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={page}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ page: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )} */}
          </TableFooter>
        </Table>
      </TableContainer>
    </BaseLayout>
  );
};
