import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { IEntities } from 'shared/interfaces';
import { useDebounce } from 'shared/hooks';
import { EntitiesService } from 'shared/services/api/Entities';

interface IDataContextData {
  dataEntity: IEntities[];
  error: string;
  loading: boolean;
  totalCount: number;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDataEntities: React.Dispatch<React.SetStateAction<IEntities[]>>;

  getEntities: (page: number, union: number, entity: number) => Promise<IEntities[] | void>;
}

const DataContext = createContext({} as IDataContextData);

export const useEntityContext = () => {
  return useContext(DataContext);
};

export const AppDataProvider = ({ children }: { children: ReactNode }) => {
  const [dataEntity, setDataEntity] = useState<IEntities[]>();
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { debounce } = useDebounce();

  const getDataEntities = useCallback(async (page: number, union: number, entity: number) => {
    setLoading(true);

    debounce(() => {
      EntitiesService.getAll(page, union, entity).then((result) => {
        setLoading(false);
        if (result instanceof Error) {
          setError(result.message);
        } else {
          setError(null);
          setTotalCount(result.totalCount);
          setDataEntity(result.data.filter((item) => item.initials !== null));
        }
      });
    });
  }, []);

  return (
    <DataContext.Provider
      value={{
        dataEntity,
        error,
        loading,
        totalCount,
        setLoading,
        getEntities: getDataEntities,
        setDataEntities: setDataEntity,
      }}
    >
      <>{children}</>
    </DataContext.Provider>
  );
};
