import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { useDebounce } from 'shared/hooks';
import { useField } from '@unform/core';
import { SchoolQuizzesService } from 'shared/services/api';

type TAutoCompleteOption = {
  id: number;
  label: string;
};

interface IAutoCompleteProps {
  isExternalLoading?: boolean;
  school: number;
  name: string;
}
export const AutoCompletQuestion: React.FC<IAutoCompleteProps> = ({ isExternalLoading = false, school, name }) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);
  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<number | undefined>(defaultValue);

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
    });
  }, [registerField, fieldName, selectedId]);

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      SchoolQuizzesService.getBySchool(school).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
        } else {
          setOpcoes(result.map((question) => ({ id: question.question_id, label: question.question })));
        }
      });
    });
  }, [busca, selectedId]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption: any = opcoes.find((opcao) => opcao.id === selectedId);
    if (!selectedOption) return null;
    return selectedOption;
  }, [selectedId, opcoes]);

  return (
    <Autocomplete
      sx={{ height: 100 }}
      openText="Abrir"
      closeText="Fechar"
      noOptionsText="Sem opções"
      loadingText="Carregando..."
      disablePortal
      options={opcoes}
      loading={isLoading}
      disabled={isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue: any) => {
        setSelectedId(newValue?.id);
        setBusca('');
        clearError();
      }}
      popupIcon={isExternalLoading || isLoading ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField {...params} name={name} placeholder="Selecione um pergunta" error={!!error} helperText={error} />
      )}
    />
  );
};
