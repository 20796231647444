import { Environment } from 'shared/environment';
import { APIBase } from '../apiBase';
import { IStudent } from 'shared/interfaces';

// interface IData {
//   data: IStudent[];
//   meta: IMeta;
// }

type TStudentsWithTotalCount = {
  data: IStudent[];
  totalCount: number;
};
//   :year/:union/:entity/:school

const getByClasses = async (
  union: number,
  initial: string,
  school: number,
  grade: number,
  classe: string,
  filter = '%',
): Promise<TStudentsWithTotalCount | Error> => {
  try {
    const urlRelativa = `/student/list/${
      Environment.ANO_SELECIONADO
    }/${union}/${initial}/${school}/${grade}/${classe.trim()}?filter=${filter}`;

    const { data, headers } = await APIBase.get(urlRelativa);
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count']),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getStudentSocimetria = async (
  union: number,
  initial: string,
  school: number,
  grade: number,
  classe: string,
): Promise<IStudent[] | Error> => {
  try {
    const urlRelativa = `/student/sociometria/list/${Environment.ANO_SELECIONADO}/${union}/${initial}/${school}/${grade}/${classe}`;

    const { data } = await APIBase.get(urlRelativa);

    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (union: number, initial: string, school: number, student: number): Promise<IStudent | Error> => {
  try {
    const { data } = await APIBase.get(
      `/student/${Environment.ANO_SELECIONADO}/${union}/${initial}/${school}/${student}`,
    );

    if (data) {
      return data[0];
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const sync = async (
  initial: string,
  union: number,
  entity: number,
  school: number,
  grade: number,
  classe: string,
): Promise<TStudentsWithTotalCount | Error> => {
  try {
    const urlRelativa = `/student/sync/${
      Environment.ANO_SELECIONADO
    }/${union}/${entity}/${school}/${grade}/${classe.trim()}/${initial}`;

    const { data } = await APIBase.get(urlRelativa);

    if (data.type === 'success') {
      return data.message;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

export const StudentService = {
  sync,
  getById,
  getByClasses,
  getStudentSocimetria,
};
