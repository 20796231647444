import { Button, Icon, Typography } from '@mui/material';
import React from 'react';

interface IButtonOutlinedProps {
  onClick?: () => void;
  icon: string;
  label: string;
  type: any;
}

const ButtonOutlined: React.FC<IButtonOutlinedProps> = ({ onClick, icon, label, type }) => {
  return (
    <>
      <Button color="secondary" disableElevation variant={type} onClick={onClick} startIcon={<Icon>{icon}</Icon>}>
        <Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
          {label}
        </Typography>
      </Button>
    </>
  );
};

export default ButtonOutlined;
