import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  ClassList,
  EntitiesList,
  GradesList,
  Home,
  Menu,
  QuizzList,
  Sociogram,
  StudentList,
  UsersList,
  QuestionList,
} from 'pages/';
import { useDrawerContext } from 'shared/contexts';
import { MenuService } from 'shared/services/api/menu';

import { useAuth as useAuthContext } from 'shared/hooks/';
import { SchoolsList } from 'pages/Private/Schools';
import PrivateRoutes from './Private.route';
import Authentication from 'pages/authentication';
import PublicRoutes from './Public.route';
import Error404 from 'pages/Public/Error404';
import QRCodeList from 'pages/Private/QRCode';
import SocioagramGraph from 'pages/Private/SocioagramGraph';
import { QueryClientProvider, QueryClient } from 'react-query';
import ForgotPassword from 'pages/Public/ForgotPassword';
import ResetPassword from 'pages/Public/ResetPassword';

const queryClient = new QueryClient();

export const CustomRouters = () => {
  const { setDrawerOptions } = useDrawerContext();
  const { isAuthenticated, profile } = useAuthContext();

  async function getMenuItems() {
    if (!isAuthenticated) return;
    MenuService.getAll().then((result) => {
      if (result instanceof Error) {
        alert(result.message);
        return;
      }
      if (profile.role === 'union') {
        setDrawerOptions(
          result.data.data.filter(
            (item) =>
              (item.status !== 0 && item.role === 'union') ||
              (item.status !== 0 && item.role === 'all') ||
              (item.status !== 0 && item.role === 'entity'),
          ),
        );
      } else if (profile.role === 'entity') {
        setDrawerOptions(
          result.data.data.filter(
            (item) => (item.status !== 0 && item.role === 'entity') || (item.status !== 0 && item.role === 'all'),
          ),
        );
      } else {
        setDrawerOptions(
          result.data.data.filter(
            (item) => (item.status !== 0 && item.role === 'school') || (item.status !== 0 && item.role === 'all'),
          ),
        );
      }
    });
  }

  useEffect(() => {
    getMenuItems();
  }, [profile]);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Routes>
          {/* Rotas Publicas     */}
          <Route path="/" element={<Authentication />} />

          <Route path="*" element={<Error404 />} />
          <Route path="recoverPassword" element={<ForgotPassword />} />
          <Route path="resetPassword" element={<ResetPassword />} />

          <Route path="/Public" element={<PublicRoutes />}>
            <Route path="Sociogram/:initial/:school/:code/:question" element={<Sociogram />} />
            {/* <Route index element={<Authentication />} /> */}
          </Route>
          {/* Rotas privadas     */}
          <Route path="/Private" element={<PrivateRoutes />}>
            <Route path="Users" element={<UsersList />} />
            <Route path="Home" element={<Home />} />
            {/* <Route path="Home" element={<SociogramTest />} /> */}

            <Route path="Menu" element={<Menu />} />
            <Route path="Entities" element={<EntitiesList />} />
            <Route path="Schools/:id/:initial" element={<SchoolsList />} />
            <Route path="Grades/:entity/:school" element={<GradesList />} />
            <Route path="Classes/:entity/:school/:grade" element={<ClassList />} />
            <Route path="Student/:entity/:school/:grade/:classe" element={<StudentList />} />
            <Route path="questionnaires/" element={<QuizzList />} />
            <Route path="Questions/" element={<QuestionList />} />
            <Route path="QrcodeList/:initial/:entity/:school/:grade/:classe/:question" element={<QRCodeList />} />
            <Route
              path="SociogramGraph/:initial/:entity/:school/:grade/:classe/:question"
              element={<SocioagramGraph />}
            />
          </Route>
        </Routes>
      </QueryClientProvider>
    </>
  );
};
