import React from 'react';
import { useOutlet } from 'react-router-dom';

//import SiderBar from "../components/SiderBar";

function PublicRoutes() {
  const outlet = useOutlet();
  console.log('aqui');
  return <>{outlet}</>;
}

export default PublicRoutes;
