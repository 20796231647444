import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Box, Typography, Grid, Avatar, Paper, TextField, LinearProgress } from '@mui/material';
//import images from 'constants/images';
import { recoverPassword } from 'shared/services/api/authentication';
import { logo } from 'constants/index';
import { BaseLayout } from 'shared/layouts';
import { ToolbarList } from 'shared/components';

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {`Copyright © União Norte Brasileira -   ${new Date().getFullYear()} | Departamento de Bussiness Inteligence`}
      </Typography>
    );
  }

  async function handlerSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    const recover = new FormData(event.currentTarget);
    const email: any = recover.get('email');

    const data = await recoverPassword(email);
    console.log(data);

    setLoading(false);
    if (data.type === 'success') {
      alert(data.message);
      navigate('/resetPassword', { replace: true });
    } else {
      alert(data.message);
    }
  }

  return (
    <BaseLayout
      title="Home"
      toolBar={
        <ToolbarList
          title=""
          showButtonBack
          showButtonNew={false}
          onClickBack={() => navigate('/', { replace: true })}
          // navigate('/Private/Entity/detail/new')}
        />
      }
    >
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar alt="Remy Sharp" src={logo} sx={{ width: 96, height: 96 }} />

            <Box component="form" noValidate onSubmit={handlerSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} disabled={loading}>
                Enviar
              </Button>
              {loading && <LinearProgress variant="indeterminate" />}

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </BaseLayout>
  );
};

export default ForgotPassword;
