import { Environment } from 'shared/environment';
import { IEntities, IMeta, IUser } from 'shared/interfaces';
import { APIBase } from '../apiBase';
import { getUserLocalStorage } from '../authentication';

interface IData {
  data: IEntities[];
  meta: IMeta;
}

type TEntitieWithTotalCount = {
  data: IEntities[];
  totalCount: number;
};

const getAll = async (page = 1, union: number, entity: number): Promise<TEntitieWithTotalCount | Error> => {
  const userOffline: IUser = getUserLocalStorage();

  try {
    let urlRelativa: string;
    if (userOffline.role === 'union') {
      urlRelativa = `/${userOffline.role}/entity/list/${union}?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}`;
    } else {
      urlRelativa = `/union/entity/list/${union}/${entity}?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}`;
    }

    const { data, headers } = await APIBase.get(urlRelativa);
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: number): Promise<IData | Error> => {
  try {
    const { data } = await APIBase.get(`/entity/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};
// /entity/sync/:union/:code/:initial

const sync = async (union: number, entity: number, initial: string): Promise<TEntitieWithTotalCount | Error> => {
  const userOffline: IUser = getUserLocalStorage();

  try {
    const urlRelativa = `${userOffline.role}/entity/sync/${union}/${entity}/${initial}`;

    const { data } = await APIBase.get(urlRelativa);

    if (data.type === 'success') {
      return data.message;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const syncAll = async (union: number): Promise<string | Error> => {
  const userOffline: IUser = getUserLocalStorage();

  try {
    const urlRelativa = `${userOffline.role}/entity/syncall/${union}`;

    const { data } = await APIBase.get(urlRelativa);
    if (data.type === 'success') {
      return data.message;
    }

    return new Error('Erro ao Sincronizar as Entidade.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const create = () => {
  return;
};

export const EntitiesService = {
  getAll,
  sync,
  getById,
  create,
  syncAll,
};
