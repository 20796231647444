import { ExitToAppRounded, Menu } from '@mui/icons-material';
import { AppBar, Avatar, Box, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useDrawerContext } from 'shared/contexts';
import { useAuth as useAuthContext } from 'shared/hooks';
import { logo } from 'constants/index';

const MenuBar: React.FC = () => {
  const { logout, profile } = useAuthContext();
  const { toggleDrawerOpen } = useDrawerContext();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const mleft = smDown ? 0 : theme.spacing(0);

  return (
    <>
      <AppBar position="static" variant="elevation" elevation={1} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Box sx={{ width: '50vw', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} ml={mleft}>
            <Avatar alt="Remy Sharp" src={logo} sx={{ width: 48, height: 48 }} />
          </Box>
          <Box sx={{ width: '50vw', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} ml={mleft}>
            {smDown && (
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="info"
                onClick={toggleDrawerOpen}
              >
                <Menu />
              </IconButton>
            )}
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} sx={{ width: '50vw' }}>
            {profile && (
              <>
                {!smDown && (
                  <Typography variant="body1" component="div" mr={2}>
                    {profile.email}
                  </Typography>
                )}

                <Box marginLeft={3}>
                  <IconButton
                    size="medium"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    sx={{ color: theme.palette.grey[100] }}
                    onClick={() => logout()}
                  >
                    <ExitToAppRounded />
                  </IconButton>
                </Box>
              </>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default MenuBar;
