import { IMenu } from 'shared/interfaces';
import { Environment } from 'shared/environment';
import { APIBase } from '../apiBase';

interface IData {
  data: IMenu[];
  statusCode: number;
  type: string;
}

type TMenuWithTotalCount = {
  data: IData;
  totalCount?: number;
};

const getAll = async (page = 1): Promise<TMenuWithTotalCount | Error> => {
  try {
    const urlRelativa = `/menu?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}`;

    const { data, headers } = await APIBase.get(urlRelativa);
    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getByRoleId = async (roleId: number): Promise<TMenuWithTotalCount | Error> => {
  try {
    const urlRelativa = `menu/role/${roleId}`;

    const { data } = await APIBase.get(urlRelativa);

    if (data.data) {
      return {
        data,
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

//const create = async (): Promise<any> => {};

//const updateById = async (): Promise<any> => {};

//const deleteById = async (): Promise<any> => {};

export const MenuService = {
  getAll,
  getByRoleId,
  //create,
  //updateById,
  //deleteById,
};
