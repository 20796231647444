import axios from 'axios';
import { Environment } from 'shared/environment/';
import { responseInterceptor, errorInterceptor } from './interceptors';

const APIBase = axios.create({
  baseURL: Environment.API_ENDPOINT,
});

APIBase.interceptors.response.use(
  (response) => responseInterceptor(response),
  (error) => errorInterceptor(error),
);

export { APIBase };
