import { Environment } from 'shared/environment';
import { APIBase } from '../apiBase';
import { IMeta, ISchoolQuizz } from 'shared/interfaces';

interface IData {
  data: ISchoolQuizz[];
  meta: IMeta;
}

type TSchoolQuizzesWithTotalCount = {
  data: IData;
  totalCount: number;
};

const getAll = async (page: number, filterId = '%'): Promise<TSchoolQuizzesWithTotalCount | Error> => {
  try {
    const { data, headers } = await APIBase.get(
      `/schoolquizzes?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}?filterid=${filterId}`,
    );

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const getById = async (union: number, id: number): Promise<ISchoolQuizz[] | Error> => {
  try {
    const { data } = await APIBase.get(`/schoolquizzes/${id}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const getByIdOnStudent = async (union: number, id: number): Promise<ISchoolQuizz[] | Error> => {
  try {
    const { data } = await APIBase.get(`/schoolquizzes/student/list/${id}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const getBySchool = async (school: number): Promise<ISchoolQuizz[] | Error> => {
  try {
    const { data } = await APIBase.get(`/schoolquizzes/school/student/list/${school}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const getQuizzBySchool = async (school: number): Promise<ISchoolQuizz[] | Error> => {
  try {
    const { data } = await APIBase.get(`/schoolquizzes/quiz/school/student/list/${school}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const changeStatus = async (id: number): Promise<TSchoolQuizzesWithTotalCount | Error> => {
  try {
    const { data, headers } = await APIBase.put(`/schoolquizzes/${id}`);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: ISchoolQuizz): Promise<ISchoolQuizz | Error> => {
  try {
    const { data } = await APIBase.post<ISchoolQuizz>(`schoolquizzes/`, dados);
    console.error(data);
    if (data) {
      return data;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (union: number, id: number, dados: ISchoolQuizz): Promise<ISchoolQuizz | Error> => {
  try {
    const { data } = await APIBase.put(`/quiz/${union}/${id}`, dados);
    if (data) {
      return data;
    }
    return new Error('Erro ao Atualizar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

export const SchoolQuizzesService = {
  getAll,
  changeStatus,
  create,
  updateById,
  getById,
  getByIdOnStudent,
  getBySchool,
  getQuizzBySchool,
};
