import React from 'react';

import { Box, Button, Icon, TextField, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';

interface IToolbarListProps {
  title: string;
  textOfSearch?: string;
  showInputSearch?: boolean;
  onChangeSearchText?: (novoTexto: string) => void;
  textButtonNew?: string;
  showButtonNew?: boolean;
  onClickNew?: () => void;
  onClickCustom?: () => void;

  buttonIcon?: string;
  textButtonCustom?: string;
  showButtonCustom?: boolean;
  textButtonBack?: string;
  showButtonBack?: boolean;
  onClickBack?: () => void;
}
export const ToolbarList: React.FC<IToolbarListProps> = ({
  title,
  textOfSearch = '',
  onChangeSearchText,
  showInputSearch = false,
  onClickNew,
  onClickCustom,
  textButtonNew = 'Novo',
  showButtonNew = true,
  buttonIcon = 'add',
  textButtonCustom = 'Adicionar Questionário',
  showButtonCustom = false,

  textButtonBack = 'Voltar',
  showButtonBack = false,
}) => {
  const theme = useTheme();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  function onClickBack() {
    history.go(-1);
  }

  return (
    <Box display="flex" alignItems="center" paddingX={2} justifyContent="space-between">
      <Box flex={1} display="flex" justifyContent="start" flexDirection={'column'}>
        {showButtonBack && (
          <Button
            color="primary"
            disableElevation
            variant="text"
            onClick={() => onClickBack()}
            startIcon={<Icon>arrow_back</Icon>}
            sx={{ width: 100 }}
          >
            {textButtonBack}
          </Button>
        )}
        <Typography
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipses"
          variant={smDown ? 'subtitle1' : mdDown ? 'subtitle2' : 'subtitle1'}
        >
          {title}
        </Typography>
      </Box>
      <Box gap={1} padding={1} display="flex" alignItems="center" height={theme.spacing(7)}>
        {showInputSearch && (
          <TextField
            size="small"
            value={textOfSearch}
            placeholder="Pesquisar..."
            onChange={(e) => onChangeSearchText?.(e.target.value)}
          />
        )}

        <Box flex={1} display="flex" justifyContent="end">
          {showButtonCustom && (
            <Button
              sx={{ mr: 2 }}
              color="secondary"
              disableElevation
              variant="text"
              onClick={onClickCustom}
              endIcon={<Icon>add</Icon>}
            >
              {textButtonCustom}
            </Button>
          )}
          {showButtonNew && (
            <Button
              color="secondary"
              disableElevation
              variant="text"
              onClick={onClickNew}
              endIcon={<Icon>{buttonIcon}</Icon>}
            >
              {textButtonNew}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
