import React from 'react';
import { Navigate, useLocation, useOutlet } from 'react-router-dom';
import SiderBar from 'shared/components/SiderBar/';
import { useAuth as useAuthContext } from 'shared/hooks';

//import SiderBar from "../components/SiderBar";

function PrivateRoutes() {
  const { isAuthenticated } = useAuthContext();

  const location = useLocation();
  const outlet = useOutlet();

  if (!isAuthenticated) {
    return <Navigate to="/" replace state={{ from: location }} />;
  }

  return <SiderBar>{outlet}</SiderBar>;
}

export default PrivateRoutes;
