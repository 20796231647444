import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from '@mui/material';
import { AutoCompletQuestion } from './AutoCompletQuestion';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { VForm, useVForm } from 'shared/forms';
import { ISchools } from 'shared/interfaces';

// import { Container } from './styles';

interface alertProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  school: ISchools;
  initial: string;
  student: number;
  isLoading: boolean;
  grade: number;
  classe: any;
  entity: number;
  local: string;
}

const AlertSelectQuestion: React.FC<alertProps> = ({
  setOpen,
  open,
  school,
  student,
  isLoading,
  initial,
  local,
  entity,
  grade,
  classe,
}) => {
  const { formRef, save } = useVForm();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  function handleSave(data: any) {
    if (local === 'qrcode') {
      navigate(`/Private/QrcodeList/${initial}/${entity}/${school.code}/${grade}/${classe}/${data.quiz_id}`);
    } else if (local === 'sociometria') {
      navigate(`/Private/SociogramGraph/${initial}/${entity}/${school.school_id}/${grade}/${classe}/${data.quiz_id}`);
    } else {
      navigate(`/Public/Sociogram/${initial}/${school.code}/${student}/${data.quiz_id}`);
    }
  }

  return (
    <div>
      <VForm ref={formRef} onSubmit={handleSave}>
        <Dialog
          fullWidth
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Selecione uma questão</DialogTitle>
          <DialogContent>
            <Paper elevation={0} sx={{ width: '80%', height: '80%' }}>
              <AutoCompletQuestion name="quiz_id" isExternalLoading={isLoading} school={school.school_id} />
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="secondary" onClick={save}>
              salvar
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleClose}>
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </VForm>
    </div>
  );
};

export default AlertSelectQuestion;
