import { LinearProgress, Paper, Typography } from '@mui/material';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BaseLayout } from 'shared/layouts';
import QRCode from 'react-qr-code';
import { useDebounce, useAuth as useAuthContext } from 'shared/hooks/';
import { StudentService } from 'shared/services/api/Stundets';
import { useParams } from 'react-router-dom';
import { IStudent } from 'shared/interfaces';
import { useSchoolContext } from 'shared/contexts';

const QRCodeList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { debounce } = useDebounce();
  const { school, grade, classe, initial, question } = useParams();
  const { profile } = useAuthContext();
  const { dataSchool } = useSchoolContext();
  const page = 1;
  const [rows, setRows] = useState<IStudent[]>([]);

  function getStudents(page: number, union: number) {
    setIsLoading(true);

    debounce(() => {
      setIsLoading(true);
      StudentService.getStudentSocimetria(union, initial, Number(school), Number(grade), classe).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setRows(result);
        }
      });
    });
  }

  useEffect(() => {
    getStudents(page, profile.union);
  }, []);

  return (
    <BaseLayout title="QRCode">
      {isLoading && <LinearProgress variant="indeterminate" color="success" />}
      {rows?.map((row) => (
        <Box
          key={row.id}
          width={'97%'}
          height={'auto'}
          border="dotted"
          display={'flex'}
          alignItems={'center'}
          p={2}
          mt={5}
        >
          <Paper sx={{ height: '25%', width: '20%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <QRCode
              id="QRCodeScaled"
              size={256}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={`https://sociometria.unb.org.br/Public/Sociogram/${initial}/${school}/${row.code}/${question}`}
              viewBox={`0 0 256 256`}
            />
          </Paper>
          <Box>
            <Typography variant="h4" ml={2}>
              Escola: {dataSchool.filter((item) => item.code === Number(school))[0].name}
            </Typography>
            <Typography variant="h4" ml={2}>
              NOME: {row.name}
            </Typography>
            <Typography variant="h4" ml={2}>
              Turma: {row.class_code}
            </Typography>
            <Typography variant="h4" ml={2}>
              Série:{row.grade_code}
            </Typography>
            <Typography variant="body1" ml={2}>
              Link:
              {`https://sociometria.unb.org.br/Public/Sociogram/${initial}/${school}/${row.code}/${question}`}
            </Typography>
          </Box>
        </Box>
      ))}
    </BaseLayout>
  );
};

export default QRCodeList;
