const prod = process.env.REACT_APP_PROD;
const API_PROD = process.env.REACT_APP_API_PROD;
const API_DEV = process.env.REACT_APP_API_DEV;
const YEAR = process.env.REACT_APP_YEAR;
export const Environment = {
  /**
   * Define a quantidade de linhas a ser carregada por padrão nas listagens
   */
  LIMITE_DE_LINHAS: 10,
  /**
   * Placeholder exibido nas inputs
   */
  INPUT_DE_BUSCA: 'Pesquisar...',
  /**
   * Texto exibido quando nenhum registro é encontrado em uma listagem
   */
  LISTAGEM_VAZIA: 'Nenhum registro encontrado.',

  API_ENDPOINT: prod === 'true' ? API_PROD : API_DEV,

  ANO_SELECIONADO: YEAR,
};
