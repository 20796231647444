import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Modal,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IQuestionQuizz, IStudent, IStudentSociogram } from 'shared/interfaces';
import { StudentService } from 'shared/services/api/Stundets';
import { Theme, styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { AutoCompletStudents } from './AutoCompletStudents';
import { IVFormErrors, VForm, useVForm } from 'shared/forms';
import * as yup from 'yup';
import { QuestionQuizzesService, QuizService, studentSociogramService } from 'shared/services/api';
import { useDebounce } from 'shared/hooks';
import { useSchoolContext } from 'shared/contexts';
const formValidationSchema: yup.SchemaOf<IStudentSociogram> = yup.object().shape({
  school_id: yup.number().required(),
  quiz_id: yup.number().required(),
  question_id: yup.number().required(),
  student_id: yup.number().required(),
  student_one_id: yup.number().required(),
  student_two_id: yup.number().required(),
  student_three_id: yup.number().required(),
});

export const Sociogram: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  const { dataSchool } = useSchoolContext();
  const [student, setStudent] = useState<IStudent>();
  const { code, initial, question, school } = useParams();

  const theme = useTheme();
  const { formRef, save } = useVForm();
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { debounce } = useDebounce();
  const [quizzName, setQuizzName] = useState<string>();
  const [questionExist, setQuestionExist] = useState<IStudentSociogram[]>([]);
  const [questions, setQuestions] = useState<IQuestionQuizz[]>([]);
  const [questionId, setQuestionId] = useState<any>('');
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    height: '20vh',
  }));

  function getIdQuizz(id: number) {
    debounce(() => {
      studentSociogramService.getByQuizAndStudent(Number(question), id).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          // setQuestionExist(true);
          getQuizz();
          throw result;
        } else {
          getQuizz();
          setQuestionExist(result);
        }
      });
    });
  }

  function getQuizz() {
    debounce(() => {
      QuizService.getByQuestion(Number(question)).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          throw result;
        } else {
          // setOpcoes(result.map((question) => ({ id: question.quiz_id, label: question.question })));
          setQuizzName(result[0].question);
          getQuestionsByQuiz();
        }
      });
    });
  }

  function getQuestionsByQuiz() {
    debounce(() => {
      QuestionQuizzesService.getByQuizz(Number(question)).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          throw result;
        } else {
          setQuestions(result);
        }
      });
    });
  }

  const handleSave = (data: IStudentSociogram) => {
    if (data.student_one_id === data.student_two_id) {
      alert('Não é permitido duplicar a escolha de amigos. Verifique os amigos selecionados e tente novamente');
      return;
    }
    if (data.student_one_id === data.student_three_id) {
      alert('Não é permitido duplicar a escolha de amigos. Verifique os amigos selecionados e tente novamente');
      return;
    }
    if (data.student_two_id === data.student_three_id) {
      alert('Não é permitido duplicar a escolha de amigos. Verifique os amigos selecionados e tente novamente');
      return;
    }

    const dataREceived = {
      school_id: Number(`${student.entity_code}${student.school_code}`),
      student_id: Number(student.student_id),
      quiz_id: Number(question),
      question_id: questionId,
      ...data,
    };
    formValidationSchema
      .validate(dataREceived, { abortEarly: false })
      .then((validateddata) => {
        setIsLoading(true);
        studentSociogramService.create(validateddata).then((result) => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            alert('Questão gravado com sucesso.');
            getIdQuizz(Number(student.student_id));
            getStudent();
          }
        });
        setIsLoading(false);
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach((error) => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  function getStudent() {
    debounce(() => {
      StudentService.getById(5111, initial, Number(school), Number(code))
        .then((result) => {
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setStudent(result);
            getIdQuizz(Number(result.student_id));
          }
          setLoading(false);
        })
        .catch((error) => {
          throw error;
        });
    });
  }

  useEffect(() => {
    setIsLoading(true);
    getStudent();
    setQuestionId(null);
  }, [code]);

  return (
    <Box>
      <>
        {isLoading && (
          <Modal open={isLoading} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 300,
                height: 100,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
              }}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Aguarde gravando...
              </Typography>

              <CircularProgress color="inherit" />
            </Box>
          </Modal>
        )}
        <Box
          pl={5}
          mb={5}
          display={'flex'}
          alignItems={'start'}
          justifyContent={'center'}
          flexDirection={'column'}
          width={'98vw'}
          bgcolor={theme.palette.secondary.contrastText}
          height={theme.spacing(15)}
        >
          <Typography variant="h4">Sociometria</Typography>

          <Typography variant="h5">{quizzName}</Typography>
        </Box>

        <VForm ref={formRef} onSubmit={handleSave}>
          <Container sx={{ width: '100%', height: '15vh' }}>
            {student && (
              <>
                <Grid container spacing={2} height={'50vh'}>
                  <Grid item xs={12} sm={12} md={10} lg={10} xl={10} height={'40%'}>
                    <Item sx={{ pl: 2, pt: 2 }}>
                      <Typography
                        bgcolor={'#616161'}
                        p={2}
                        color={'#f1f1f1'}
                        variant={smDown ? 'h6' : mdDown ? 'h4' : 'h4'}
                      >
                        {dataSchool?.filter((item) => item.code === Number(school))[0].name}
                      </Typography>

                      <Typography variant={smDown ? 'h6' : mdDown ? 'h5' : 'h5'}>{student.name}</Typography>
                      <Box>
                        <Typography textAlign={'left'} variant={'button'}>
                          Serie: {student.grade_code} -
                        </Typography>

                        <Typography variant={smDown ? 'button' : mdDown ? 'button' : 'button'}>
                          Turma: {student.class_code}
                        </Typography>
                      </Box>
                    </Item>
                  </Grid>
                  {!mdDown && (
                    <Grid item xs={2} md={2}>
                      <Item>
                        <QRCode
                          id="QRCodeScaled"
                          size={256}
                          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                          value={`http://localhost:3000/Public/Sociogram/${initial}/${student.code}/${question}`}
                          viewBox={`0 0 256 256`}
                        />
                      </Item>
                    </Grid>
                  )}

                  <>
                    {questions &&
                      questions?.map((question) => {
                        const exist = questionExist.find((item) => item.question_id === question.question_id);

                        return (
                          <>
                            {!exist?.question_id && (
                              <Grid item xs={12} sm={12} md={10} lg={10} xl={10} mb={5} key={question.id}>
                                <Item sx={{ height: '100%' }}>
                                  <Box display={'flex'} justifyContent={'space-around'}>
                                    <Box width={'32%'}>
                                      <AutoCompletStudents
                                        filter={student.student_id}
                                        name="student_one_id"
                                        classe={student.class_code}
                                        initial={initial}
                                        grade={student.grade_code}
                                        school={student.school_code}
                                        union={student.union_code}
                                        isExternalLoading={loading}
                                        question={() => setQuestionId(question.question_id)}
                                      />
                                    </Box>
                                    <Box width={'32%'}>
                                      <AutoCompletStudents
                                        filter={student.student_id}
                                        name="student_two_id"
                                        classe={student.class_code}
                                        initial={initial}
                                        grade={student.grade_code}
                                        school={student.school_code}
                                        union={student.union_code}
                                        isExternalLoading={loading}
                                        question={() => setQuestionId(question.question_id)}
                                      />
                                    </Box>
                                    <Box width={'32%'}>
                                      <AutoCompletStudents
                                        filter={student.student_id}
                                        name="student_three_id"
                                        classe={student.class_code}
                                        initial={initial}
                                        grade={student.grade_code}
                                        school={student.school_code}
                                        union={student.union_code}
                                        isExternalLoading={loading}
                                        question={() => setQuestionId(question.question_id)}
                                      />
                                    </Box>
                                  </Box>
                                  <Divider sx={{ mb: 2, mt: 2 }} />
                                  <Box>
                                    <Typography variant="h5">{question.question}</Typography>
                                  </Box>
                                  <Divider sx={{ mb: 2, mt: 2 }} />
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => {
                                      save();
                                    }}
                                  >
                                    Enviar Resposta
                                  </Button>
                                </Item>
                              </Grid>
                            )}
                          </>
                        );
                      })}
                  </>
                </Grid>
              </>
            )}
          </Container>
        </VForm>
      </>
    </Box>
  );
};
