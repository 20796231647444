import { IStudentSociogram } from './../../../interfaces/studentSociogram.type';
import { Environment } from 'shared/environment';
import { APIBase } from '../apiBase';
import { IMeta, ISchoolQuizz, ISociogram } from 'shared/interfaces';

interface IData {
  data: ISchoolQuizz[];
  meta: IMeta;
}

type TSociogramWithTotalCount = {
  data: IData;
  totalCount: number;
};

const getAll = async (page: number, filterId = '%'): Promise<TSociogramWithTotalCount | Error> => {
  try {
    const { data, headers } = await APIBase.get(
      `/schoolquizzes?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}?filterid=${filterId}`,
    );

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const getByQuestionAndStudent = async (question: number, student: number): Promise<IStudentSociogram[] | Error> => {
  try {
    const { data } = await APIBase.get(`/studentsociogram/question/${question}/${student}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const getByQuizAndStudent = async (question: number, student: number): Promise<IStudentSociogram[] | Error> => {
  try {
    const { data } = await APIBase.get(`/studentsociogram/quiz/${question}/${student}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const changeStatus = async (id: number): Promise<TSociogramWithTotalCount | Error> => {
  try {
    const { data, headers } = await APIBase.put(`/schoolquizzes/${id}`);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (dados: IStudentSociogram): Promise<ISchoolQuizz | Error> => {
  try {
    const { data } = await APIBase.post<ISchoolQuizz>(`studentsociogram/`, dados);
    console.error(data);
    if (data) {
      return data;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (union: number, id: number, dados: ISchoolQuizz): Promise<ISchoolQuizz | Error> => {
  try {
    const { data } = await APIBase.put(`/quiz/${union}/${id}`, dados);
    if (data) {
      return data;
    }
    return new Error('Erro ao Atualizar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

const getStudentByClassForGraph = async (
  quiz: number,
  schoolId: number,
  grade: number,
  classe: string,
): Promise<ISociogram[] | Error> => {
  try {
    const urlRelativa = `/sociometria/sociogram/${Environment.ANO_SELECIONADO}/${schoolId}/${grade}/${classe}/${quiz}`;

    const { data } = await APIBase.get(urlRelativa);

    if (data) {
      return data;
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

export const studentSociogramService = {
  getAll,
  changeStatus,
  create,
  updateById,
  getByQuestionAndStudent,
  getStudentByClassForGraph,
  getByQuizAndStudent,
};
