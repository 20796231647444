import { Box, Dialog, Grid, LinearProgress } from '@mui/material';
import { AutoCompletEntity, AutoCompletSchool } from 'pages/Private/Users/components';
import { useEffect, useState } from 'react';
import { ToolbarDetails } from 'shared/components';
import { IVFormErrors, useVForm, VForm, VRadioGroup, VTextField } from 'shared/forms';
import { useAuth as useAuthContext } from 'shared/hooks/';
import { IUser } from 'shared/interfaces';
import { DialogBaseLayout } from 'shared/layouts';
import { UserService } from 'shared/services/api/Users';

interface IDialogProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  id: string;
}

export const UsersDetails: React.FC<IDialogProps> = ({ setOpen, open, id }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const { formRef, save } = useVForm();
  const [initials, setInitials] = useState('');
  const { profile } = useAuthContext();

  const options =
    profile.role === 'entity'
      ? [{ value: 'school', label: 'Escola' }]
      : [
          { value: 'union', label: 'União' },
          { value: 'entity', label: 'Entidade' },
          { value: 'school', label: 'Escola' },
        ];
  useEffect(() => {
    if (id !== 'new') {
      setIsLoading(true);
      UserService.getById(Number(id)).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          alert(result.message);
          setOpen(false);
        } else {
          setName(result.data[0].email);
          // formRef.current?.setData(result.users.data[0]);
          formRef.current.setFieldValue('email', result.data[0].email);
          formRef.current.setFieldValue('name', result.data[0].name);
          formRef.current.setFieldValue('entity_code', result.data[0].entity_code);
          formRef.current.setFieldValue('role_id', result.data[0].role_id);
        }
      });
    } else {
      formRef.current?.setData({
        name: '',
        email: '',
        role_id: '',
        entity_code: '',
      });
    }
  }, [id]);

  const handleSave = async (data: IUser) => {
    try {
      setIsLoading(true);
      const dataFull = {
        role: data.role_id.toString(),
        email: data.email,
        entity_code: data.entity_code,
        school_code: data.school_code,
        name: data.full_name,
        password: data.password,
        union_code: 5111,
      };
      if (id === 'new') {
        UserService.create(dataFull).then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setOpen(false);
          }
        });
      } else {
        UserService.updateById(Number(id), dataFull).then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            setOpen(false);
          }
        });
      }
    } catch (errors) {
      const validationErrors: IVFormErrors = {};

      errors.inner.forEach((error: any) => {
        if (!error.path) return;

        validationErrors[error.path] = error.message;
      });

      formRef.current?.setErrors(validationErrors);
    }
  };

  function handleClose() {
    setOpen(false);
  }

  return (
    <VForm ref={formRef} onSubmit={handleSave}>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogBaseLayout
          title={id === 'new' ? 'Novo Usuário' : name}
          toolBar={
            <ToolbarDetails
              title={''}
              textButtomNew="Novo"
              showButtonNew={false}
              showButtonDelete={false}
              onClickSave={() => save()}
              textButtomBack="Cancelar"
              iconButtomBack="close"
              onClickBack={() => setOpen(false)}
            />
          }
        >
          {/* {isLoading && <LinearProgress variant="indeterminate" />} */}

          <Box alignItems={'center'} justifyContent={'center'} display="flex">
            <Grid
              container
              direction="column"
              padding={2}
              spacing={2}
              alignItems="center"
              display={'flex'}
              border="solid"
              borderRadius={2}
              borderColor="#eee"
            >
              {isLoading && (
                <Grid item>
                  <LinearProgress variant="indeterminate" />
                </Grid>
              )}

              <Grid container item direction="row" spacing={2}>
                <Grid item sm={8} md={8} lg={8} xl={8}>
                  <VRadioGroup options={options} name="role_id" />
                </Grid>
              </Grid>
              <Grid container item direction="row" spacing={2}>
                <Grid item md={4} lg={4} xl={4}>
                  <AutoCompletEntity setInitials={setInitials} />
                </Grid>
                <Grid item md={4} lg={4} xl={4}>
                  {initials && <AutoCompletSchool initials={initials} />}
                </Grid>
              </Grid>
              <Grid container item direction="row" spacing={2}>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <VTextField fullWidth label="Nome" name="full_name" />
                </Grid>
              </Grid>

              <Grid container item direction="row" spacing={2}>
                <Grid item sm={12} md={12} lg={12} xl={12}>
                  <VTextField fullWidth label="E-mail" name="email" />
                </Grid>
              </Grid>
              {/* {id === 'new' && (
                <Grid container item direction="row" spacing={2}>
                  <Grid item sm={12} md={12} lg={12} xl={12}>
                    <VTextField fullWidth label="Senha" name="password" type={'password'} />
                  </Grid>
                </Grid>
              )} */}
            </Grid>
          </Box>
        </DialogBaseLayout>
      </Dialog>
    </VForm>
  );
};
