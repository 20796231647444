import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

import { useDebounce } from 'shared/hooks';
import { useField } from '@unform/core';
import { StudentService } from 'shared/services/api/Stundets';

type TAutoCompleteOption = {
  id: number;
  label: string;
};

interface IAutoCompleteProps {
  isExternalLoading?: boolean;
  union: number;
  initial: string;
  school: number;
  grade: number;
  classe: string;
  name: string;
  filter: number;
  question: () => void;
}
export const AutoCompletStudents: React.FC<IAutoCompleteProps> = ({
  isExternalLoading = false,
  union,
  initial,
  school,
  grade,
  classe,
  name,
  filter,
  question,
}) => {
  const { fieldName, registerField, defaultValue, error, clearError } = useField(name);
  const { debounce } = useDebounce();

  const [selectedId, setSelectedId] = useState<number | undefined>(defaultValue);

  const [opcoes, setOpcoes] = useState<TAutoCompleteOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [busca, setBusca] = useState('');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => selectedId,
      setValue: (_, newSelectedId) => setSelectedId(newSelectedId),
    });
  }, [registerField, fieldName, selectedId]);

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      StudentService.getStudentSocimetria(union, initial, school, grade, classe).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
        } else {
          setOpcoes(
            result
              .filter((item) => item.student_id !== filter)
              .map((student) => ({
                id: student.student_id,
                label: student.name,
              })),
          );
        }
      });
    });
  }, [busca, selectedId]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;

    const selectedOption: any = opcoes.find((opcao) => opcao.id === selectedId);
    if (!selectedOption) return null;
    return selectedOption;
  }, [selectedId, opcoes]);

  return (
    <Autocomplete
      openText="Abrir"
      closeText="Fechar"
      noOptionsText="Sem opções"
      loadingText="Carregando..."
      disablePortal
      options={opcoes}
      loading={isLoading}
      disabled={isExternalLoading}
      value={autoCompleteSelectedOption}
      onInputChange={(_, newValue) => setBusca(newValue)}
      onChange={(_, newValue: any) => {
        setSelectedId(newValue?.id);
        setBusca('');
        clearError();
        question();
      }}
      popupIcon={isExternalLoading || isLoading ? <CircularProgress size={28} /> : undefined}
      renderInput={(params) => (
        <TextField {...params} name={name} placeholder="Selecione um aluno" error={!!error} helperText={error} />
      )}
    />
  );
};
