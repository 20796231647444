import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { history } from 'shared/services/api/utils/history';

import './shared/forms/TraducoesYup';

import { AppThemeProvider, DrawerProvider, AppDataProvider, AppSchoolProvider } from './shared/contexts';
import { CustomRouters } from './routes/index';
import { AuthProvider } from 'shared/hooks/useAuthetication/useAuth';
///teste
export const App = () => {
  return (
    <AuthProvider>
      <AppThemeProvider>
        <DrawerProvider>
          <AppDataProvider>
            <AppSchoolProvider>
              <HistoryRouter history={history as any}>
                <CustomRouters />
              </HistoryRouter>
            </AppSchoolProvider>
          </AppDataProvider>
        </DrawerProvider>
      </AppThemeProvider>
    </AuthProvider>
  );
};

export default App;
