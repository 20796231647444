export const errorInterceptor = (error: any) => {
  if (error.message === 'Network Error') {
    return Promise.reject(new Error('Erro de conexão.'));
  }

  if (error.response?.status === 400) {
    return Promise.reject(new Error('Pedido ou URL Inválido.')); // Do something
  }

  if (error.response?.status === 401) {
    console.log(error);
    if (error.response.data.message === 'Invalid credentials.') {
      return Promise.reject(new Error('Senha incorreta.'));
    } else if (error.message === 'User not found.') {
      return Promise.reject(new Error('Email incorreto ou usuário não existe.'));
    } else {
      return Promise.reject(new Error('Não autorizado.')); // Do something
    }
  }

  if (error.response?.status === 403) {
    return Promise.reject(new Error('Sem permissão para acessar o recurso.')); // Do something
  }

  if (error.response?.status === 404) {
    return Promise.reject(new Error('Nenhum registro encontrado.')); // Do something
  }

  if (error.response?.status === 500) {
    return Promise.reject(new Error('Erro Interno do Servidor.')); // Do something
  }

  if (error.response?.status === 501) {
    return Promise.reject(new Error('O tipo de solicitação não é tratada pelo servidor.')); // Do something
  }
  if (error.response?.status === 503) {
    return Promise.reject(new Error('Serviço Indisponível.')); // Do something
  }

  return Promise.reject(error);
};
