import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

import { IVFormErrors, VForm, VTextField, useVForm } from 'shared/forms';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { QuestionService } from 'shared/services/api';
interface alertProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  type: string;
  getData(): void;
  id: number;
  setId: React.Dispatch<React.SetStateAction<number>>;
}

export const AlertQuestionAdd: React.FC<alertProps> = ({ open, setOpen, type, getData, id = 0, setId }) => {
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  interface IFormData {
    question: string;
  }
  const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
    question: yup.string().required().min(5),
  });

  const handleClose = () => {
    getData();
    setId(0);
    setOpen(false);
  };

  useEffect(() => {
    if (type === 'edit') {
      setIsLoading(true);
      QuestionService.getById(id).then((result) => {
        if (result instanceof Error) {
          //alert(result.message);
        } else {
          formRef.current?.setData(result[0]);
        }
      });
      setIsLoading(false);
    } else {
      formRef.current?.setData({
        question: '',
      });
    }
  }, [id]);

  const handleSave = async (data: IFormData) => {
    try {
      await formValidationSchema.validate(data, { abortEarly: false });
      setIsLoading(true);

      if (type === 'new') {
        const result = await QuestionService.create(data);
        if (result instanceof Error) {
          alert(result.message);
        } else {
          alert('Questão gravado com sucesso.');
          setOpen(false);
        }
      } else {
        const result = await QuestionService.updateById(id, data);
        if (result instanceof Error) {
          alert(result.message);
        }
        alert('Questão atualizado com sucesso.');
        setId(0);
        setOpen(false);
      }
    } catch (errors) {
      const validationErrors: IVFormErrors = {};

      errors.inner.forEach((error: any) => {
        if (!error.path) return;

        validationErrors[error.path] = error.message;
      });

      formRef.current?.setErrors(validationErrors);
    } finally {
      getData();
      setIsLoading(false);
    }
  };

  return (
    <VForm ref={formRef} onSubmit={handleSave}>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Adição do Questionário</DialogTitle>
        <DialogContent>
          <DialogContentText>Digite a Pergunta.</DialogContentText>
          <VTextField name="question" fullWidth variant="outlined" />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancelar
          </Button>
          <LoadingButton
            loading={isLoading}
            color="secondary"
            loadingIndicator="Salvando…"
            variant="outlined"
            onClick={save}
          >
            Salvar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </VForm>
  );
};
