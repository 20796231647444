import { Environment } from 'shared/environment';
import { APIBase } from '../apiBase';
import { IMeta, ISchools, IUser } from 'shared/interfaces';
import { getUserLocalStorage } from '../authentication';

interface IData {
  data: ISchools[];
  meta: IMeta;
}

type TSchoolsWithTotalCount = {
  data: ISchools[];
  totalCount: number;
};

const getAll = async (union: number, initial: string): Promise<TSchoolsWithTotalCount | Error> => {
  const userOffline: IUser = getUserLocalStorage();
  try {
    let urlRelativa: string;

    if (userOffline.role === 'entity' || userOffline.role === 'union') {
      urlRelativa = `/union/school/list/${union}/${initial}`;
    } else {
      urlRelativa = `/union/school/list/${union}/${initial}`;
    }
    const { data, headers } = await APIBase.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: number): Promise<IData | Error> => {
  try {
    const { data } = await APIBase.get(`/school/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

export const SchoolService = {
  getAll,
  getById,
};
