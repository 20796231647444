import { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material';

type TVRadioGroupProps = RadioGroupProps & {
  name: string;
  options: {
    value: string;
    label: string;
  }[];
};
export const VRadioGroup: React.FC<TVRadioGroupProps> = ({ name, options }) => {
  const { fieldName, registerField, defaultValue } = useField(name);
  const [value, setValue] = useState(defaultValue || '');
  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    });
  }, [registerField, fieldName, value]);

  return (
    <RadioGroup
      row
      aria-label="type"
      name={fieldName}
      value={value}
      ref={registerField}
      onChange={(e) => setValue(e.target.value)}
    >
      {options.map((option) => (
        <FormControlLabel key={option?.value} value={option?.value} control={<Radio />} label={option?.label} />
      ))}
    </RadioGroup>
  );
};
