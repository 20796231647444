import React from 'react';
import backgroundImage from 'assets/img/bg_home.png';
import { Box } from '@mui/material';

export const Home: React.FC = () => {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  );
};
