import { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import { ISchools } from 'shared/interfaces';
import { SchoolService } from 'shared/services/api/Schools';

interface IDataContextData {
  dataSchool: ISchools[];
  error: string;
  loading: boolean;
  totalCount: number;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getSchools: (union: number, initial: string, school: number) => Promise<ISchools[] | void>;
}

const SchoolContext = createContext({} as IDataContextData);

export const useSchoolContext = () => {
  return useContext(SchoolContext);
};

export const AppSchoolProvider = ({ children }: { children: ReactNode }) => {
  const [dataSchool, setDataSchool] = useState<ISchools[]>();
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const getDataSchool = useCallback(async (union: number, initial: string) => {
    setLoading(true);
    try {
      const result = await SchoolService.getAll(union, initial);
      if (result instanceof Error) {
        throw result;
      } else {
        setTotalCount(result.totalCount);
        setDataSchool(result.data);
      }
    } catch (error) {
      if (error.message === 'Nenhum registro encontrado.') {
        return;
      }
      setError(error.message);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <SchoolContext.Provider value={{ dataSchool, error, loading, setLoading, totalCount, getSchools: getDataSchool }}>
      <>{children}</>
    </SchoolContext.Provider>
  );
};
