import { createTheme } from '@mui/material';
import { cyan } from '@mui/material/colors';

export const DarkTheme = createTheme({
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: '#616161',
          paddingTop: '10px',
          paddingBottom: '10px',
          '&.Mui-selected': {
            color: '#ffffff',
            backgroundColor: '#ffa92d',
            '&:hover': {
              backgroundColor: '#fcc16f',
            },
            '& .MuiListItemIcon-root': {
              color: '#ffffff',
            },
          },
          '&:hover': {
            backgroundColor: '#ffd8a3',
            color: '#ffffff',
            '& .MuiListItemIcon-root': {
              color: '#ffffff',
            },
          },
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '##ffa92d',
      dark: '#fcc16f',
      light: '#ffd8a3',
      contrastText: '#ffffff',
    },
    secondary: {
      main: cyan[500],
      dark: cyan[400],
      light: cyan[300],
      contrastText: '#ffffff',
    },
    background: {
      paper: '#303134',
      default: '#202124',
    },
  },
  typography: {
    allVariants: {
      color: 'white',
    },
  },
});
