import { Environment } from 'shared/environment';
import { APIBase } from '../apiBase';
import { IMeta, IQuizz } from 'shared/interfaces';

interface IData {
  data: IQuizz[];
  meta: IMeta;
}

type TQuizzesWithTotalCount = {
  data: IData;
  totalCount: number;
};

const getAll = async (union: number, page: number, filterId = '%'): Promise<TQuizzesWithTotalCount | Error> => {
  try {
    const { data } = await APIBase.get(
      `/quiz/list/${union}?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}?filterid=${filterId}`,
    );

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const getAllSchool = async (union: number, page: number, filterId = '%'): Promise<IData | Error> => {
  try {
    const { data } = await APIBase.get(
      `/quiz/list/${union}?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}?filterid=${filterId}`,
    );

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const getById = async (union = 5511, id: number): Promise<IQuizz[] | Error> => {
  try {
    const { data } = await APIBase.get(`/quiz/${union}/${id}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const getByQuestion = async (id: number): Promise<IQuizz[] | Error> => {
  try {
    const { data } = await APIBase.get(`/quiz/student/get/${id}`);

    if (data) {
      return data;
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const changeStatus = async (union: number, id: number): Promise<TQuizzesWithTotalCount | Error> => {
  try {
    const { data, headers } = await APIBase.put(`/quiz/status/${union}/${id}`);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }
    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};

const create = async (union: number, dados: IQuizz): Promise<number | Error> => {
  try {
    const { data } = await APIBase.post<IQuizz>(`quiz/${union}/`, { union_code: union, question: dados.question });

    if (data) {
      return data.id;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

const updateById = async (union: number, id: number, dados: IQuizz): Promise<IQuizz | Error> => {
  try {
    const { data } = await APIBase.put(`/quiz/${union}/${id}`, dados);
    if (data) {
      return data.data;
    }
    return new Error('Erro ao Atualizar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao atualizar o registro.');
  }
};

export const QuizService = {
  getAll,
  changeStatus,
  create,
  updateById,
  getById,
  getByQuestion,
  getAllSchool,
};
