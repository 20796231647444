import { useEffect, useMemo, useState } from 'react';
import { BaseLayout } from 'shared/layouts';
import {
  Button,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ToolbarList } from 'shared/components';
import { useAuth as useAuthContext } from 'shared/hooks/';
import { Environment } from 'shared/environment';
import { IClass } from 'shared/interfaces';
import { useEntityContext, useSchoolContext } from 'shared/contexts';
import { ClassService } from 'shared/services/api/Classes';
import { Hub } from '@mui/icons-material';
import AlertSelectQuestion from '../Components/AlertSelectQuestion';
import { StudentService } from 'shared/services/api/Stundets';

export const ClassList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { school, entity, grade } = useParams();
  const { profile } = useAuthContext();
  const { dataSchool } = useSchoolContext();
  const { dataEntity } = useEntityContext();
  const initials = dataEntity.filter((item) => item.code === Number(entity))[0].initials;
  const navigate = useNavigate();

  const [rows, setRows] = useState<IClass[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [idQuestion, setIdQuestion] = useState<number>();
  const [open, setOpen] = useState(false);
  const [classSelected, setClassSelected] = useState<any>();

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  async function getGrades(page: number, union: number) {
    try {
      setIsLoading(true);
      const schoolNumber = Number(school);
      const gradeNumber = Number(grade);
      const result = await ClassService.getByGrade(page, union, initials, schoolNumber, gradeNumber);

      if (result instanceof Error) {
        throw result;
      } else {
        const { data, totalCount } = result;
        setTotalCount(totalCount);
        setRows(data);
      }
    } catch (error) {
      if (error.message === 'Nenhum registro encontrado.') {
        return;
      }
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  function openSelecQuestion(id: number, local: string, classe: any) {
    setIdQuestion(id);
    setClassSelected(classe);
    setOpen(true);
  }

  useEffect(() => {
    getGrades(page, profile.union);
  }, [page, entity, school]);

  const handleSync = (
    initial: string,
    union: number,
    entity: number,
    school: number,
    grade: number,
    classe: string,
  ) => {
    setIsLoading(true);
    StudentService.sync(initial, union, entity, school, grade, classe).then((result) => {
      setIsLoading(false);
      if (result instanceof Error) {
        alert(result.message);
      } else {
        alert(result);
        getGrades(page, profile.union);
      }
    });
  };

  return (
    <BaseLayout
      title="Home"
      toolBar={
        <ToolbarList
          title={`Lista de Turmas - ${
            dataSchool?.filter((item) => item.code === Number(school))[0].name
          } - Total: ${totalCount}`}
          showInputSearch={false}
          showButtonNew={false}
          textButtonNew="Sincronizar"
          onClickNew={() => console.log}
          showButtonBack
        />
      }
    >
      {open && (
        <AlertSelectQuestion
          entity={Number(entity)}
          grade={Number(grade)}
          classe={classSelected}
          local={'sociometria'}
          isLoading={isLoading}
          open={open}
          setOpen={setOpen}
          school={dataSchool.filter((item) => item.code === Number(school))[0]}
          student={idQuestion}
          initial={initials}
        />
      )}
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Ações</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Codigo da Serie</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Turma</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Turno</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Total Alunos</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.code}>
                <TableCell width={'20%'}>
                  <Button
                    sx={{ mr: 0.2 }}
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={() =>
                      handleSync(
                        dataEntity.filter((item) => item.code === row.entity_code)[0].initials,
                        row.union_code,
                        row.entity_code,
                        row.school_code,
                        row.grade_code,
                        row.code,
                      )
                    }
                  >
                    SINCRONIZAR
                  </Button>
                  <Button
                    sx={{ mr: 0.2 }}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() =>
                      navigate(`/Private/Student/${row.entity_code}/${row.school_code}/${row.grade_code}/${row.code}`)
                    }
                  >
                    Acessar
                  </Button>
                </TableCell>
                <TableCell width={'15%'}>{row.grade_code}</TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>{row.shift}</TableCell>
                <TableCell>{row.total_students}</TableCell>
                <TableCell>
                  <Button
                    sx={{ mt: 0.2, pl: 2.2, pr: 2.2 }}
                    variant="contained"
                    color="secondary"
                    size="small"
                    startIcon={<Hub />}
                    onClick={() => openSelecQuestion(row.id, 'null', row.code.replace(/\s/g, ''))}
                  >
                    Sociograma
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {totalCount === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={6}>
                  <LinearProgress variant="indeterminate" color="success" />
                </TableCell>
              </TableRow>
            )}
            {totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={page}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ page: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </BaseLayout>
  );
};
