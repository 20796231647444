import {
  Button,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BaseLayout } from 'shared/layouts';

import { ToolbarList } from 'shared/components';
import { useEntityContext } from 'shared/contexts';
import { Environment } from 'shared/environment';
import { useAuth as useAuthContext } from 'shared/hooks/';

export const EntitiesList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { profile } = useAuthContext();
  const { dataEntity, loading, error, getEntities, totalCount, setLoading } = useEntityContext();
  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  console.log('profile', profile);
  async function getDataEntities(page: number, union: number, entity: number) {
    setLoading(true);

    try {
      await getEntities(page, union, entity);
      if (error) {
        alert(error);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  useEffect(() => {
    getDataEntities(page, profile.union, profile.entity);
  }, [page, profile]);

  return (
    <BaseLayout
      title="Home"
      toolBar={
        <ToolbarList
          title="Lista de Entidades"
          showInputSearch={false}
          showButtonNew={false} //{profile.role === 'union' ? true : false}
          textButtonNew="Sincronizar"
          buttonIcon="sync"
        />
      }
    >
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Ações nas escolas</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Entidade</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Sigla</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Nome</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataEntity
              ?.filter((e) => e.code && e.initials)
              ?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell width={'16%'}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => navigate(`/Private/Schools/${row.code}/${row.initials}`)}
                    >
                      Acessar
                    </Button>
                  </TableCell>
                  <TableCell>{row.code}</TableCell>
                  <TableCell>{row.initials}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.name}</TableCell>

                  {/* <TableCell>{row.city}</TableCell>
                <TableCell>{row.state}</TableCell> */}
                </TableRow>
              ))}
          </TableBody>
          {totalCount === 0 && !loading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}

          <TableFooter>
            {loading && (
              <TableRow>
                <TableCell colSpan={6}>
                  <LinearProgress variant="indeterminate" color="success" />
                </TableCell>
              </TableRow>
            )}
            {totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={page}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ page: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </BaseLayout>
  );
};
