import { Typography } from '@mui/material';
import React from 'react';
import { ToolbarDetails } from 'shared/components';
import { BaseLayout } from 'shared/layouts';

// import { Container } from './styles';

export const Menu: React.FC = () => {
  return (
    <BaseLayout
      title="Home"
      toolBar={<ToolbarDetails title="Menu" showButtonBack showButtonDelete showButtonNew showButtonSave />}
    >
      <Typography>MENU</Typography>
    </BaseLayout>
  );
};
