import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { format } from 'date-fns';
import { IVFormErrors, VForm, useVForm, VDateField } from 'shared/forms';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { QuizService, SchoolQuizzesService } from 'shared/services/api';
import { useAuth as useAuthContext } from 'shared/hooks';
import { AutoCompletQuizz } from './AutoCompletQuizz';
interface alertProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  getData(): void;
  idsSchool: any[];
}

export const AlertSchoolQuestion: React.FC<alertProps> = ({ open, setOpen, idsSchool, getData }) => {
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const { profile } = useAuthContext();

  interface IFormData {
    school_id: number;
    quiz_id: number;
    question_id: number;
    end_date: Date;
    start_date: Date;
  }
  const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
    school_id: yup.number().notRequired(),
    quiz_id: yup.number().notRequired(),
    question_id: yup.number().notRequired(),
    end_date: yup.date(),
    start_date: yup.date(),
  });

  const handleClose = () => {
    getData();
    setOpen(false);
  };

  useEffect(() => {
    setIsLoading(true);
    QuizService.getAll(profile.union, 1).then((result) => {
      if (result instanceof Error) {
        return; //alert(result.message);
      }
    });
    setIsLoading(false);
  }, [idsSchool]);

  const handleSave = (data: IFormData) => {
    if (data.start_date >= data.end_date) {
      alert('Data inicial não pode ser menor que a final');
      return;
    }
    idsSchool.map((ids) => {
      formValidationSchema
        .validate(data, { abortEarly: false })
        .then((validateddata) => {
          setIsLoading(true);
          const data = {
            school_id: ids,
            quiz_id: validateddata.quiz_id,
            question_id: validateddata.question_id,
            start_date: format(validateddata.start_date, 'yyyy-MM-dd'),
            end_date: format(validateddata.end_date, 'yyyy-MM-dd'),
          };
          SchoolQuizzesService.create(data).then((result) => {
            setIsLoading(false);

            if (result instanceof Error) {
              alert(result.message);
              return;
            }
          });
        })
        .catch((errors: yup.ValidationError) => {
          const validationErrors: IVFormErrors = {};

          errors.inner.forEach((error) => {
            if (!error.path) return;

            validationErrors[error.path] = error.message;
          });

          formRef.current?.setErrors(validationErrors);
        });

      setIsLoading(false);
      alert('Gravdo com sucesso');
      setOpen(false);
    });
  };

  return (
    <VForm ref={formRef} onSubmit={handleSave}>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Adição do Questionário</DialogTitle>
        <DialogContent>
          <AutoCompletQuizz isExternalLoading={isLoading} />
          <Box display={'flex'} justifyContent={'space-between'} mt={5}>
            <Box>
              <DialogContentText>Data Inicial</DialogContentText>
              <VDateField name="start_date" />
            </Box>
            <Box>
              <DialogContentText>Data Final</DialogContentText>
              <VDateField name="end_date" />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancelar
          </Button>
          <LoadingButton
            loading={isLoading}
            color="secondary"
            loadingIndicator="Salvando…"
            variant="outlined"
            onClick={save}
          >
            Salvar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </VForm>
  );
};
