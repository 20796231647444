import { Environment } from 'shared/environment';
import { APIBase } from '../apiBase';
import { IGrade, IMeta } from 'shared/interfaces';

interface IData {
  data: IGrade[];
  meta: IMeta;
}

type TGradesWithTotalCount = {
  data: IGrade[];
  totalCount: number;
};
//   :year/:union/:entity/:school

const getBySchool = async (
  page = 1,
  union: number,
  initial: string,
  school: number,
): Promise<TGradesWithTotalCount | Error> => {
  try {
    const urlRelativa = `/grade/list/${Environment.ANO_SELECIONADO}/${union}/${initial}/${school}?page=${page}&limit=${Environment.LIMITE_DE_LINHAS}`;

    const { data, headers } = await APIBase.get(urlRelativa);

    if (data) {
      return {
        data,
        totalCount: Number(headers['x-total-count'] || Environment.LIMITE_DE_LINHAS),
      };
    }

    return new Error('Erro ao listar os registros.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao listar os registros.');
  }
};

const getById = async (id: number): Promise<IData | Error> => {
  try {
    const { data } = await APIBase.get(`/school/${id}`);

    if (data) {
      return data;
    }

    return new Error('Erro ao consultar o registro.');
  } catch (error) {
    return new Error((error as { message: string }).message || 'Erro ao consultar o registro.');
  }
};
///grade/sync/:year/:union/:entity/:school/:initial
const sync = async (
  union: number,
  entity: number,
  school: number,
  initial: string,
): Promise<TGradesWithTotalCount | Error> => {
  try {
    const urlRelativa = `/grade/sync/${Environment.ANO_SELECIONADO}/${union}/${entity}/${school}/${initial}`;

    const { data } = await APIBase.get(urlRelativa);

    if (data.type === 'success') {
      return data.message;
    }

    return new Error('Erro ao criar o registro.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao criar o registro.');
  }
};

export const GradeService = {
  sync,
  getById,
  getBySchool,
};
