import React, { useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'shared/hooks/';
import { useParams } from 'react-router-dom';
import { ISociogram } from 'shared/interfaces';
import { studentSociogramService } from 'shared/services/api';
import { Box, LinearProgress, useTheme } from '@mui/material';
import Graph from 'react-vis-network-graph';

const graphOptions = {
  height: '1000px',
  width: '1500px',
  layout: {
    hierarchical: false,
  },
  nodes: {
    color: '#ff0000',
    fixed: false,
    font: '12px arial white',
    shadow: true,
    shape: 'circle',
    size: 10,
    shapeProperties: {
      borderRadius: 6,
    },
  },

  edges: {
    color: '#000',
    endPointOffset: {
      from: 0,
      to: 0,
    },
    smooth: {
      type: 'cubicBezier',
      forceDirection: 'none',
    },
    scaling: {
      min: 50,
      max: 500,
    },

    arrows: {
      to: {
        enabled: true,
        scaleFactor: 0.6,
        type: 'arrow',
      },

      from: {
        enabled: false,
        scaleFactor: 0.6,
        type: 'arrow',
      },
    },
  },
  physics: {
    barnesHut: {
      gravitationalConstant: -5050,
      centralGravity: 0.35,
    },
    minVelocity: 0.75,
  },
};

export interface NetNodes {
  id: number;
  label: string;
  color: string;
}

export interface NetEdges {
  from: number;
  to: number;
}

interface Graph {
  counter: number;
  graph: {
    edges: NetEdges[];
    nodes: NetNodes[];
  };
}

const SocioagramGraph: React.FC = () => {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const { debounce } = useDebounce();
  const { school, grade, classe, question } = useParams();
  const [rows, setRows] = useState({ counter: 0, graph: { nodes: [], edges: [] } } as Graph);

  const getColor = (gender: string) => (gender === 'M' ? theme.palette.primary.light : theme.palette.error.light);
  const getReducedName = useCallback((name: string) => {
    const [first, second] = name.split(' ');
    return `${first} ${second}`;
  }, []);

  const transformData = (data: ISociogram[]) => {
    if (!data?.length) {
      return {
        nodes: [],
        edges: [],
      };
    }

    const nodes: any[] = [];
    const edges: any[] = [];

    data.reduce((_, next, index) => {
      console.log(index);
      nodes.push({
        id: next.student_id,
        label: getReducedName(next.name),
        color: getColor(next.gender),
      } as NetNodes);

      edges.push({ from: next.student_id, to: next.student_one_id });
      edges.push({ from: next.student_id, to: next.student_two_id });
      edges.push({ from: next.student_id, to: next.student_three_id });

      return next;
    }, {} as ISociogram);

    return { nodes, edges };
  };

  const loadData = useCallback(() => {
    getStudents();
  }, [question]);

  function getStudents() {
    setIsLoading(true);
    debounce(() => {
      setIsLoading(true);
      studentSociogramService
        .getStudentByClassForGraph(Number(question), Number(school), Number(grade), classe)
        .then((result) => {
          setIsLoading(false);
          if (result instanceof Error) {
            alert(result.message);
          } else {
            const transformedData = transformData(result);
            const counter = transformedData?.nodes?.length ?? 0;
            const graph = transformedData;
            console.log(graph);
            setRows({ counter, graph });
          }
        });
    });
  }
  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <>
      {isLoading && <LinearProgress variant="indeterminate" color="success" />}
      <Box>{rows?.graph?.nodes && <Graph graph={rows?.graph} options={graphOptions} key={Math.random()} />}</Box>;
    </>
  );
};

export default SocioagramGraph;
