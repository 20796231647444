import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

import { IVFormErrors, VForm, VTextField, useVForm } from 'shared/forms';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { QuizService } from 'shared/services/api';
import { useAuth as useAuthContext } from 'shared/hooks';
interface alertProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  type: string;
  getData(): void;
  id: number;
  setId: React.Dispatch<React.SetStateAction<number>>;
}

export const AlertQuestionAdd: React.FC<alertProps> = ({ open, setOpen, type, getData, id = 0, setId }) => {
  const { formRef, save } = useVForm();
  const [isLoading, setIsLoading] = useState(false);
  const { profile } = useAuthContext();
  interface IFormData {
    question: string;
    union_code: number;
  }
  const formValidationSchema: yup.SchemaOf<IFormData> = yup.object().shape({
    union_code: yup.number().notRequired(),
    question: yup.string().required().min(5),
  });

  const handleClose = () => {
    getData();
    setId(0);
    setOpen(false);
  };

  async function getDataEdit(idEdit: number) {
    if (type === 'edit') {
      setIsLoading(true);
      try {
        const result = await QuizService.getById(5111, idEdit);

        if (result instanceof Error) {
          //alert(result.message);
        } else {
          result.map((item) => formRef.current.setData(item));
        }
      } catch (error) {
        //alert(error.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      formRef.current?.setData({
        question: '',
        union_code: '',
      });
    }
  }

  useEffect(() => {
    getDataEdit(id);
  }, [id]);

  const handleSave = (data: IFormData) => {
    formValidationSchema
      .validate(data, { abortEarly: false })
      .then((validateddata) => {
        setIsLoading(true);

        if (type === 'new') {
          QuizService.create(profile.union, validateddata).then((result) => {
            if (result instanceof Error) {
              alert(result.message);
            } else {
              getData();
              alert('Questão gravado com sucesso.');
              setOpen(false);
            }
          });
        } else {
          QuizService.updateById(profile.union, id, validateddata).then((result) => {
            if (result instanceof Error) {
              alert(result.message);
            }
            getData();
            alert('Questão atualizado com sucesso.');
            setId(0);
            setOpen(false);
          });
        }
      })
      .catch((errors: yup.ValidationError) => {
        const validationErrors: IVFormErrors = {};

        errors.inner.forEach((error) => {
          if (!error.path) return;

          validationErrors[error.path] = error.message;
        });

        formRef.current?.setErrors(validationErrors);
      });
  };

  return (
    <VForm ref={formRef} onSubmit={handleSave}>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Adição do Questionário</DialogTitle>
        <DialogContent>
          <DialogContentText>Digite a Pergunta.</DialogContentText>
          <VTextField name="question" fullWidth variant="outlined" />
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Cancelar
          </Button>
          <LoadingButton
            loading={isLoading}
            color="secondary"
            loadingIndicator="Salvando…"
            variant="outlined"
            onClick={save}
          >
            Salvar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </VForm>
  );
};
