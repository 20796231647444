import { useEffect, useState } from 'react';
import { useField } from '@unform/core';
import 'dayjs/locale/pt-br';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

type TVDateFieldProps = any & {
  name: string;
};
export const VDateField: React.FC<TVDateFieldProps> = ({ name, ...rest }) => {
  const { fieldName, registerField } = useField(name);
  const [value, setValue] = useState<Dayjs | null>(null);
  //  const [value, setValue] = useState(defaultValue || '');

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => value,
      setValue: (_, newValue) => setValue(newValue),
    });
  }, [registerField, fieldName, value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <DatePicker
        {...rest}
        value={value}
        onChange={(newValue: Dayjs) => setValue(newValue)}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};
