import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';

export const LightTheme = createTheme({
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: '#616161',
          paddingTop: '10px',
          paddingBottom: '10px',
          '&.Mui-selected': {
            color: grey['A100'],
            backgroundColor: '#003366',
            '&:hover': {
              backgroundColor: '#003366',
            },
            '& .MuiListItemIcon-root': {
              color: grey['A100'],
            },
          },
          '&:hover': {
            backgroundColor: '#00478e',
            color: grey['A100'],
            '& .MuiListItemIcon-root': {
              color: grey['A100'],
            },
          },
        },
      },
    },
  },
  palette: {
    mode: 'light',

    primary: {
      main: '#002A56',
      dark: '#004182',
      light: ' #0156aa',
      contrastText: '#eee',
    },
    secondary: {
      main: '#3e8391',
      dark: '#4b9faf',
      light: '#56b6c9',
      contrastText: '#ffffff',
    },

    background: {
      paper: '#ffffff',
      default: '#f7f6f3',
    },
  },
});
