import {
  Button,
  Chip,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BaseLayout } from 'shared/layouts';

import { ToolbarList } from 'shared/components';
import { Environment } from 'shared/environment';
import { useAuth as useAuthContext, useDebounce } from 'shared/hooks/';
import { IUser } from 'shared/interfaces';
import { UserService } from 'shared/services/api/Users';
import { UsersDetails } from '../UsersDetails';

export const UsersList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<IUser[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [id, setId] = useState('new');
  const { profile } = useAuthContext();

  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  const search = useMemo(() => {
    return searchParams.get('search') || '';
  }, [searchParams]);

  function getUsers() {
    setIsLoading(true);

    debounce(() => {
      UserService.getAll(page, profile.union, profile.entity, search).then((result) => {
        setIsLoading(false);
        if (result instanceof Error) {
          if (result.message === 'Nenhum registro encontrado.') return;
          alert(result.message);
        } else {
          setTotalCount(result.data.meta.total);
          setRows(result.data.data);
        }
      });
    });
  }

  useEffect(() => {
    getUsers();
  }, [page, open, search]);

  const handleDisable = (id: number, status: boolean) => {
    setIsLoading(true);
    UserService.disable(id, status).then((result) => {
      setIsLoading(false);
      if (result instanceof Error) {
        if (result.message === 'Nenhum registro encontrado.') return;
        alert(result.message);
      } else {
        getUsers();
        alert('Usuario desativado com sucesso.');
      }
    });
  };

  function handleOpen(id: string) {
    setId(id);
    setOpen(true);
  }

  return (
    <BaseLayout
      title="Home"
      toolBar={
        <ToolbarList
          title="Lista de Usuários"
          showInputSearch={true}
          textOfSearch={search}
          onChangeSearchText={(text) => setSearchParams({ search: text, page: '1' }, { replace: true })}
          showButtonNew
          textButtonNew="Novo"
          onClickNew={() => handleOpen('new')}
          // navigate('/Private/Entity/detail/new')}
        />
      }
    >
      <UsersDetails setOpen={setOpen} open={open} id={id} />
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Ações</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Entidade</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Nome</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>E-mail</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Tipo</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Tooltip title="Editar registro">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => handleOpen(row.id.toString())}
                    >
                      Editar
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {row.role === 'union' ? row.union_code : row.role === 'school' ? row.school_code : row.entity_code}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.role}</TableCell>

                <TableCell>
                  <Chip
                    sx={{ width: 70 }}
                    size="small"
                    label={row.status ? 'Ativo' : 'Inativo'}
                    color={row.status ? 'success' : 'warning'}
                    onClick={() => handleDisable(row.id, !row.status)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {totalCount === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <LinearProgress variant="indeterminate" />
                </TableCell>
              </TableRow>
            )}
            {totalCount > 0 && totalCount > Environment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={page}
                    count={Math.ceil(totalCount / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ page: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </BaseLayout>
  );
};
