import { useMemo, useState } from 'react';
import { BaseLayout } from 'shared/layouts';
import {
  Button,
  Chip,
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { Done, UnpublishedRounded } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import { AlertQuestionAdd, ToolbarList } from 'shared/components';
import { useDebounce, useAuth as useAuthContext } from 'shared/hooks/';
import { Environment } from 'shared/environment';
import { IMeta, IQuizz } from 'shared/interfaces';
import { QuizService } from 'shared/services/api';
import { AlertQuestionQuizz } from '../QuestionsQuizz/AlertQuestionQuizz';
import { UseQueryResult, useQuery } from 'react-query';
interface IData {
  data: IQuizz[];
  meta: IMeta;
}

export const QuizzList: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { debounce } = useDebounce();
  const { profile } = useAuthContext();
  const [openEdit, setOpenEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState<string>();
  const [id, setId] = useState<number>();
  const page = useMemo(() => {
    return Number(searchParams.get('page') || '1');
  }, [searchParams]);

  const { data, isLoading }: UseQueryResult<IData, Error> = useQuery(
    ['quizz', page],
    async () => await QuizService.getAll(profile.union, page),
  );

  function updateStatusQuestions(union: number, id: number) {
    debounce(() => {
      QuizService.changeStatus(union, id).then((result) => {
        if (result instanceof Error) {
          alert(result.message);
        }
      });
    });
  }

  function handleClickOpenNew() {
    setType('new');
    setOpenEdit(true);
  }

  async function handleClickOpenEdit(id: number) {
    setType('edit');
    await setId(id);
    setOpenEdit(true);
  }
  function opeQuestions(id: number) {
    setId(id);
    setOpen(true);
  }

  return (
    <BaseLayout
      title="Home"
      toolBar={
        <ToolbarList
          title="Lista de Questionários"
          showInputSearch={false}
          showButtonNew={true}
          textButtonNew="Novo"
          onClickNew={() => handleClickOpenNew()}
        />
      }
    >
      {openEdit && (
        <AlertQuestionAdd
          setId={setId}
          getData={() => console}
          open={openEdit}
          setOpen={setOpenEdit}
          type={type}
          id={id}
        />
      )}
      {open && <AlertQuestionQuizz idQuestion={id} open={open} setOpen={setOpen} />}
      <TableContainer component={Paper} variant="outlined" sx={{ m: 1, width: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Ações</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Questionário</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Button variant="contained" color="primary" size="small" onClick={() => handleClickOpenEdit(row.id)}>
                    Editar
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    sx={{ ml: 0.5 }}
                    onClick={() => opeQuestions(row.id)}
                  >
                    Perguntas
                  </Button>
                </TableCell>
                <TableCell>{row.question}</TableCell>
                <TableCell>
                  <Chip
                    color={row.status ? 'success' : 'error'}
                    label={row.status ? 'Ativo' : 'Inativo'}
                    onClick={() => updateStatusQuestions(profile.union, row.id)}
                    deleteIcon={row.status ? <Done /> : <UnpublishedRounded />}
                    onDelete={() => updateStatusQuestions(profile.union, row.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {data?.meta.total === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}

          <TableFooter>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={6}>
                  <LinearProgress variant="indeterminate" color="success" />
                </TableCell>
              </TableRow>
            )}
            {data?.meta.total > 0 && data?.meta.total > Environment.LIMITE_DE_LINHAS && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Pagination
                    page={page}
                    count={Math.ceil(data?.meta.total / Environment.LIMITE_DE_LINHAS)}
                    onChange={(_, newPage) => setSearchParams({ page: newPage.toString() }, { replace: true })}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableFooter>
        </Table>
      </TableContainer>
    </BaseLayout>
  );
};
